import React from 'react';
import agent from '../../agent';
import { getJsonWithAuth, getStringWithAuth, postAsyncWithAuth } from '../../FetchExtensions';
import { BlobItem } from '../../models/blobs/BlobItem';
import { Customer } from '../../models/Customer';
import Blobs, { BlobsAPI } from './Blobs';

const api: BlobsAPI = {
    getBlobItems: async (customerId: string, startDate: Date, endDate: Date, abortController: AbortController) => {
        let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

        const params = new URLSearchParams({ startDate: adjustedStartDate, endDate: adjustedEndDate });
        return getJsonWithAuth<BlobItem[]>(`blobs/${customerId}/mar?${params.toString()}`, abortController);
    },
    getPHIAccess: async () => agent.Configuration.getPHIAccess(),
    getBlobItemContents: async (customerId: string, blobItem: BlobItem) => await getStringWithAuth(`blobs/${customerId}/mar/${encodeURIComponent(blobItem.id)}`),
    resubmitBlobItem: async (customerId: string, blobItem: BlobItem) => await postAsyncWithAuth(`blobs/${customerId}/mar/${encodeURIComponent(blobItem.id)}`),
    bulkResubmitBlobItems: async (customerId: string, startDate: Date, endDate: Date) => {
        let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

        const params = new URLSearchParams({ startDate: adjustedStartDate, endDate: adjustedEndDate });
        return await postAsyncWithAuth(`blobs/${customerId}/mar?${params.toString()}`);
    }
}

interface Props {
    customer: Customer;
}

export default function Mar({ customer }: Props) {
    console.log('Customer: ' + customer);
    if (!customer.isCernerCustomer) {
        return (
            <div className="page-not-found">
                <h2>
                    Customer '{customer.name}' is not configured to manage MAR files.
                </h2>
            </div>
        );
    }
    return (
        <Blobs
            customerId={customer.id}
            columns={[
                { key: 'name', label: 'Name', columnSizeCss: 'col-6' },
                { key: 'createdOn', label: 'CreatedOn', columnSizeCss: 'col-2' }
            ]}
            api={api}
        />
    )
}