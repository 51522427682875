import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavItem } from 'reactstrap';
import { Route, Switch } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { isInternal } from '../auth/Utils.js';
import { getABCUrl, getABCLinks } from '../auth/authConfig.js';
import { doLogout } from '../auth/AuthProvider.js';
import './NavMenu.css';
import Dropdown from '../components/dropdown/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            abc_url: null,
            reports: this.props.reportsList || [],
            abc_links: {
                links: []
            },
            cx_user: this.props.user || null
        };
    }

    async componentDidMount() {
        var url = await getABCUrl();
        var links = {links: []};
        var internalSession = isInternal();
        if (!internalSession) {
            links = await getABCLinks();
        }

        await this.setState({ abc_url: url, abc_links: links });

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleInternalSignout() {
        return await doLogout();
    }

    renderNavItemFromReport(report, i, callback) {
        return (<NavDropdown.Item className='workflow-nav-item' as={Link} key={i} to={report.url} onClick={() => callback(report.navName)}>{report.navName}</NavDropdown.Item>)
    }

    render() {
        var isInternalSession = isInternal();

        const navDropdown = !isInternalSession ? (<NavDropdown className='workflow-nav' title="Workflows" id="reports-dropdown">{this.state.reports.map((r, i) => { return this.renderNavItemFromReport(r, i, (name) => { this.setState({ activeReport: name }); }) })}</NavDropdown>) : null;
        const activeReport = this.state.activeReport ? <div className='active-report'>{this.state.activeReport}</div> : null;

        return (
            <div>
                <div id="top-ribbon">
                    <div className="rco-banner">
                        {this.state.abc_links.links.map((link) => {
                            return (<div className="pull-left"><a className="signout-nav" href={link.href}>{link.display}</a></div>);
                            })
                        }
                    </div>
                    <div className="top-right">
                        {!isInternalSession && <div>{this.state.abc_links.userName} {(this.state.abc_links.userName && (this.state.abc_links.firstName || this.state.abc_links.lastName)) && <span>&nbsp;|&nbsp;</span>} {this.state.abc_links.firstName} {this.state.abc_links.lastName} &nbsp; &nbsp; <a tag={Link} className="signout-nav" href={this.state.abc_url + 'hss/hssb2b/en/logout'}>Sign Out</a></div>}
                        {isInternalSession && this.state.cx_user && <div>{this.state.cx_user.username} {this.state.cx_user.username && this.state.cx_user.name && <span>&nbsp;|&nbsp;</span>} {this.state.cx_user.name} &nbsp; &nbsp; <button type="button" className="btn btn-link signout-nav" onClick={this.handleInternalSignout.bind(this)}>Sign Out</button></div>}
                    </div>
                </div>
                <div id="middle-ribbon">
                    <div className="rco-banner">
                        <NavLink to={isInternalSession ? '/' : '/reports'}>Pharmacy Revenue Insights</NavLink>
                        {/* This element forces the bold font to load. Please don't remove it. */}
                        <span className="load-font"></span>
                    </div>
                </div>
                <div id="bottom-ribbon">
                    <div style={{ display: 'flex' }}>
                        <Switch>
                            <Route path='/errors' render={() => <div style={{ width: "300px" }}></div>} />
                            <Route render={() => this.props.customers.length > 1 && <Dropdown customers={this.props.customers} selectedCustomer={this.props.selectedCustomer} setSelectedCustomer={this.props.setSelectedCustomer} />} />
                        </Switch>
                        {/*{this.props.customers.length > 1 && <Dropdown customers={this.props.customers} selectedCustomer={this.props.selectedCustomer} setSelectedCustomer={this.props.setSelectedCustomer} />}*/}
                        {navDropdown}
                        {activeReport}
                        <nav className="nav">
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/reports">Reports</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/hl7-messages">Messages</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/claims">Claims</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/mar-messages">MAR Files</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/errors">Errors</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to="/audit-data">Audit Data</NavLink></NavItem>}
                            {isInternalSession && <NavItem><NavLink tag={Link} className="rco-navlink" activeClassName="rco-navlink-selected" to={() => `/admin`}>Admin</NavLink></NavItem>}
                        </nav>

                        <Navbar className="navbar-expand-sm rco-navbar">
                            <Container>
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                    <ul className="navbar-nav flex-grow">

                                    </ul>
                                </Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </div>
        );
    }
}
