import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { getJsonWithAuth } from '../../FetchExtensions';
import { Customer } from '../../models/Customer';
import { CustomerTable, CustomerColumn } from './tables/CustomersTable';
import TableSpinner from './tables/TableSpinner';

const api = {
    getCustomer: async (customerId: string, abortController: AbortController) => {
        return getJsonWithAuth<Customer>(`api/customers/${customerId}/admin`, abortController);
    }
}

interface Props {
    customerId: string;
    setSelectedCustomer: Function;
    handleDeletedCustomer: Function;
}

export default function Admin({ customerId, setSelectedCustomer, handleDeletedCustomer }: Props) {
    const [customer, setCustomer] = useState<Customer>({
        id: '',
        name: '',
        dbName: ''
    });


    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState<CustomerColumn[]>([
        { key: 'id', label: 'ID', type: 'string', notEditable: true },
        { key: 'name', label: 'Name', type: 'string' },
        { key: 'powerBIWorkspaceId', label: 'PowerBI Workspace', type: 'string' },
        { key: 'isCernerCustomer', label: 'Cerner?', type: 'boolean', notEditable: true },
        { key: 'dbName', label: 'Database', type: 'string' },
        { key: 'transactionsContainerName', label: 'HL7 Tx Container', type: 'string', notEditable: true },
        { key: 'claimsFilesContainerName', label: 'Claims Container', type: 'string', notEditable: true },
        { key: 'marFilesContainerName', label: 'MAR Container', type: 'string' },
        { key: 'invoiceUnits', label: 'Invoice Units', type: 'array' },
        { key: 'carveOutPayers', label: 'Carve Out Payers', type: 'array' },
        { key: 'seriesAccounts', label: 'Series Accounts', type: 'array' },
        { key: 'claimsFilesSFTPFolder', label: 'Claims Files SFTP Folder', type: 'string' },
        { key: 'claimsSFTPBlobContainer', label: 'Claims SFTP Blob Container', type: 'string' },
        { key: 'claimEncountersSFTPBlobContainer', label: 'Claim Encounters SFTP Blob Container', type: 'string' },
        { key: 'claimEncountersMappingSFTPFolder', label: 'Claim Encounters Mapping SFTP Folder', type: 'string' },
        { key: 'claimEncountersMappingBlobContainer', label: 'Claim Encounters Mapping Blob Container', type: 'string' },
        { key: 'claimEncountersMappingStagingBlobContainer', label: 'Claim Encounters Mapping Staging Blob Container', type: 'string' },
        { key: 'marFilesSFTPFolder', label: 'MAR Files SFTP Folder', type: 'string' },
        { key: 'marFilesSFTPBlobContainer', label: 'MAR Files SFTP Blob Container', type: 'string' },
        { key: 'marFileStagingContainer', label: 'MAR File Staging Container', type: 'string' },
        { key: 'sftpBlobContainer', label: 'SFTP Blob Container', type: 'string' },
        { key: 'harStatusSFTPBlobContainer', label: 'HAR Status SFTP Blob Container', type: 'string' },
        { key: 'harStatusBlobContainer', label: 'HAR Status Blob Container', type: 'string' },
        { key: 'harStatusStagingBlobContainer', label: 'HAR Status Staging Blob Container', type: 'string' },
        { key: 'claimAccountsSFTPBlobContainer', label: 'Claim Accounts SFTP Blob Container', type: 'string' },
        { key: 'claimAccountsBlobContainer', label: 'Claim Accounts Blob Container', type: 'string' },
        { key: 'claimAccountsStagingBlobContainer', label: 'Claim Accounts Staging Blob Container', type: 'string' },
        { key: 'claimIDPrefixToStrip_837', label: 'Claim ID Prefix to Strip - 837', type: 'string' },
        { key: 'deleteNonDrug837', label: 'Delete Non-Drug 837', type: 'boolean' },
        { key: 'active', label: 'Active?', type: 'boolean' },
        { key: 'patientIDType', label: 'Patient ID Type', type: 'string' },
        { key: 'usePatientMRNForFIN', label: 'Use Patient MRN for FIN?', type: 'boolean' },
        { key: 'useClaimAccountMappingFile', label: 'Use Claim Account Mapping File?', type: 'boolean' }
    ]);

    const handleGetCustomers = useCallback(async (customerId: string, abortController: AbortController) => {
        setLoading(true);
        return await api.getCustomer(customerId, abortController)
            .then((response: Customer) => {
                // Process the response in order to add any extra properties to the list of columns for a customer
                // Basically:
                //  - Take the response object and get all properties (keys).
                //  - Filter any keys that are already in the `columns` array.
                //  - Create a new column for each property. These get returned as an array from the map.
                //  - Create a `Set` in order to remove any duplicates
                //  - Create an `Array` from the `Set`.
                var extraCols = Array.from(new Set(Object.keys(response).filter(k => !columns.find(el => el.key === k)).map(k => {
                    var n: CustomerColumn = { key: k as keyof Customer, label: k, type: 'string' };
                    return n;
                })));

                setColumns([...columns, ...extraCols]);

                return setCustomer(response);
            })
            .catch((err) => console.debug(err))
            .finally(() => setLoading(false));
    }, []);


    useEffect(() => {
        var abortController = new AbortController();

        handleGetCustomers(customerId, abortController);

        return () => {
            abortController.abort();
        }
    }, [customerId, handleGetCustomers]);

    return (
        <Container>
            <div>&nbsp;</div>
            {loading ? <TableSpinner /> :
                <CustomerTable
                    columns={columns}
                    customer={customer}
                    setSelectedCustomer={setSelectedCustomer}
                    handleDeletedCustomer={handleDeletedCustomer}
                />
            }
        </Container>
    )
}