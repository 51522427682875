import React from 'react';
import { Spinner } from 'reactstrap';

export default function TableSpinner() {
	return(
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
			<Spinner animation='border' style={{width: '3rem', height: '3rem'}} variant='primary' role='status' />
			<p><em>Loading...</em></p>
		</div>
	)
}