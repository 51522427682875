import React, { Component, Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import { getAuthHeader } from '../auth/AuthProvider.js';
import { DivotIcon, ClockIcon, CircleXIcon, CircleCheckIcon, SingleDocumentIcon, MultiDocumentIcon, CaretDownFill, CaretUpFill } from '../styles/icons.js';

export class MessageTable extends Component {

    constructor(props) {
        super(props)

        this.state = {};
    }

    render() {
        var self = this;
        var circleCheck = (<CircleCheckIcon color="green" />);
        var circleX = (<CircleXIcon color="red" />);
        var clock = (<ClockIcon color="#e5ad06" />);
        var arrow = (<DivotIcon reversed={this.props.reversed} />);

        if (!(this.props.messages && this.props.messages.length)) {
            return (<div className="d-flex justify-content-center tbl-no-results">No results</div>);
        }

        return (
            <table className='table table-striped rco-table' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th className="text-nowrap">
                            <button type="button" className="btn btn-link" data-attr="displayName" onClick={this.props.handleSort}>
                                ID{this.props.sortedBy === 'displayName' && arrow}
                            </button>
                        </th>
                        <th className="text-nowrap">
                            <button type="button" className="btn btn-link" data-attr="timestampMilliseconds" onClick={this.props.handleSort}>
                                Timestamp{this.props.sortedBy === 'timestampMilliseconds' && arrow}
                            </button>
                        </th>
                        <th className="text-nowrap">
                            <button type="button" className="btn btn-link" data-attr="customer" onClick={this.props.handleSort}>
                                Customer{this.props.sortedBy === 'customer' && arrow}
                            </button>
                        </th>
                        <th className="text-nowrap">
                            <button type="button" className="btn btn-link" data-attr="status" onClick={this.props.handleSort}>
                                Status{this.props.sortedBy === 'status' && arrow}
                            </button>
                        </th>
                        {this.props.additionalColumns.map((x) => {
                            return (
                            <th className="text-nowrap">
                                    <button type="button" className="btn btn-link" data-attr={x.key} onClick={self.props.handleSort}>
                                        {x.title}{self.props.sortedBy === x.key  && arrow}
                                </button>
                            </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.messages.map(
                            function (msg) {
                                var status = msg.metadata['status'];
                                if (msg.page === self.props.current_page) {
                                    return (
                                        <tr key={msg.blobName}>
                                            <td>
                                                <button type="button" className="btn btn-link" onClick={self.props.handleMessageClick} data-message={msg.blobName} data-metadata={JSON.stringify(msg.metadata)} data-toggle="modal" data-target="#MessageModal">
                                                    {msg.displayName !== null ? msg.displayName : <em title={msg.blobName}>Unknown ID</em>}
                                                </button>
                                            </td>
                                            <td>{msg.timestampMilliseconds === null ? "" : new Date(msg.timestampMilliseconds).toLocaleString()}</td>
                                            <td>{msg.customer}</td>
                                            <td className='center-icon' title={status}>{status === 'Errored' ? circleX : status === 'Processed' ? circleCheck : status === 'Received' || status === 'Resubmitted' ? clock : ''}</td>
                                            {self.props.additionalColumns.map((x) => {
                                                return (<td>{msg.metadata[x.key]}</td>);
                                            })}


                                        </tr>);
                                }
                                else {
                                    return (null);
                                }
                            })
                    }
                </tbody>
            </table>
        );
    }
}

export class PageBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_page: 1,
            rows_per_page: 50,
            max_page_buttons: 10,
            total_pages : this.props.count / 50
        };

        this.getPreviousPage = this.getPreviousPage.bind(this);
        this.getLastPage = this.getLastPage.bind(this);
    }

    renderPagesEtcButton(lastPage, totalPages) {
        if (lastPage === totalPages - 2) {
            return (<li className="page-item" key={(totalPages - 1).toString()}><button className="page-link user-select-none" data-page={totalPages - 1} onClick={this.handlePageClick.bind(this, totalPages - 1)}>{(totalPages - 1).toString()}</button></li>);
        }
        else if (lastPage < totalPages - 1) {
            return (<li className="page-item disabled"><button className="page-link user-select-none" disabled tabIndex="-1">…</button></li>);
        }
        else {
            return (null);
        }
    }

    renderPagesLastButton(lastPage, totalPages) {
        if (lastPage < totalPages) {
            return (<li className="page-item" key={totalPages.toString()}><button className="page-link user-select-none" data-page={totalPages} onClick={this.handlePageClick.bind(this, totalPages)}>{totalPages.toString()}</button></li>);
        }
        else {
            return (null);
        }
    }

    getPreviousPage() {
        if (this.state.current_page <= 1) {
            return 1;
        }
        return this.state.current_page - 1;
    }

    getNextPage() {
        var totalPages = Math.ceil(this.props.count / this.props.rows_per_page);
        if (this.state.current_page >= totalPages) {
            return totalPages;
        }
        return this.state.current_page + 1;
    }

    getLastPage() {
        return Math.ceil(this.props.count / this.props.rows_per_page);
    }

    handlePageClick(pageIx) {
        if (this.props.handlePageClick) {
            this.props.handlePageClick(pageIx);
        }
        this.setState({ current_page: pageIx });
    }

    render() {
        if (this.props.count === 0) {
            return (null);
        }

        var self = this;
        var totalPages = Math.ceil(this.props.count / this.props.rows_per_page);
        var pagesToShow = Math.min(totalPages, this.state.max_page_buttons);
        var prevDisabled = this.state.current_page === 1;
        var nextDisabled = (this.state.current_page === totalPages) || (totalPages === 0);
        var firstPage, lastPage;
        if (totalPages === 1) {
            return (null);
        }
        if (pagesToShow < totalPages) {
            firstPage = Math.min(Math.max(1, this.state.current_page - Math.floor((pagesToShow - 1) / 3)), totalPages - pagesToShow + 1);
            lastPage = Math.min(totalPages, firstPage + pagesToShow - 1);
            if (lastPage < totalPages) {
                lastPage -= 2;
                pagesToShow -= 2;
            }
        }
        else {
            firstPage = 1;
            lastPage = totalPages;
        }
        var pages = Array(pagesToShow);
        for (var ix = firstPage; ix <= lastPage; ix++) {
            pages[ix - firstPage] = ix;
        }

        return (
            <nav>
                <ul className="pagination">
                    <li {...prevDisabled ? { className: "page-item disabled" } : { className: "page-item" }} key="-3">
                        <button className="page-link user-select-none" onClick={(e) => self.handlePageClick(1)} {...prevDisabled ? { tabIndex: -1, disabled: true } : {}}>«</button>
                    </li>
                    <li {...prevDisabled ? { className: "page-item disabled" } : { className: "page-item" }} key="-2">
                        <button className="page-link user-select-none" onClick={(e) => self.handlePageClick(self.getPreviousPage())} {...prevDisabled ? { tabIndex: -1, disabled: true } : {}}>Previous</button>
                    </li>
                    {
                        pages.map(
                            function (ix) {
                                return (
                                    <li {...self.state.current_page === ix ? { className: "page-item active" } : { className: "page-item" }} key={ix.toString()}>
                                        <button className="page-link user-select-none" data-page={ix} onClick={() => self.handlePageClick(ix) }>
                                            {ix.toString()}
                                            {self.state.current_page === ix ? <span className="sr-only">(current)</span> : (null)}
                                        </button>
                                    </li>);
                            })
                    }
                    {this.renderPagesEtcButton(lastPage, totalPages)}
                    {this.renderPagesLastButton(lastPage, totalPages)}
                    <li {...nextDisabled ? { className: "page-item disabled" } : { className: "page-item" }} key="-1">
                        <button className="page-link user-select-none" onClick={(e) => self.handlePageClick(self.getNextPage())} {...nextDisabled ? { tabIndex: -1, disabled: true } : {}}>Next</button>
                    </li>
                    <li {...nextDisabled ? { className: "page-item disabled" } : { className: "page-item" }} key="0">
                        <button className="page-link user-select-none" onClick={(e) => self.handlePageClick(self.getLastPage())} {...nextDisabled ? { tabIndex: -1, disabled: true } : {}}>»</button>
                    </li>
                </ul>
            </nav>)
    }
}

export class DateFilter extends Component {
    static _rangeLimitMs = 691_200_000;

    constructor(props) {
        super(props);

        this.dateDiff = dateDiff;
        this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
        this.state = {
            filter: props.filter
        }

        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleFilterChange(e) {
        this.setState({ filter: e.target.value });
        this.props.handleFilterChange(e);
    }

    async handleClearButtonClick(e) {
        await this.setState({ filter: '' });
        this.props.handleClearButtonClick(e)
    }

    render() {
        var startDate = (this.props.start_date ? this.props.start_date : newStartDate()).toISOString().slice(0, 10);
        var endDate = (this.props.end_date ? this.props.end_date : newEndDate()).toISOString().slice(0, 10);

        var diff = this.dateDiff(this.props.start_date, this.props.end_date);
        var tooLongRange = diff !== null && diff > DateFilter._rangeLimitMs;
        var invalidRange = this.props.start_date > this.props.end_date;
        let helpText = tooLongRange
            ? <div className="invalid-feedback">Date range can't be longer than one week</div>
            : invalidRange ? <div className="invalid-feedback">Start date can't be after end date</div> : (null);

        var inputClass = { className: tooLongRange || invalidRange ? "form-control mr-sm-2 mx-2 is-invalid" : "form-control mr-sm-2 mx-2" };

        var bulkResubmit = this.props.baseUrl ? <BulkResubmitButton baseUrl={this.props.baseUrl} type="button" variant="secondary" startDate={startDate} endDate={endDate}>Bulk Resubmit</BulkResubmitButton> : null;
        var actionableFilter = this.props.handleFilterClick ? (<div className="form-check mx-2"><input type="checkbox" className="form-check-input" id="ShowActionable" {...{ checked: this.props.action_only }} onChange={this.props.handleFilterClick} /><label className="form-check-label" htmlFor="ShowActionable">Actionable only</label></div>) : null;
        var refreshButton = this.props.handleRefresh ? (<RefreshButton handleClick={this.props.handleRefresh} />) : (null);
        var loadingSpinner = this.props.loading ? <div className="spinner-border" role="status"><span className="sr-only">Loading…</span></div> : (null);

        return (
            <div className="form-inline my-2" id="dateFilterDiv">
                <label htmlFor="FromDateInput">From date</label>
                <input type="date" {...inputClass} id="FromDateInput" name="FromDateInput" value={startDate} onChange={this.props.handleStartDateChange} />
                <label htmlFor="ToDateInput">To date</label>
                <input type="date" {...inputClass} id="ToDateInput" name="ToDateInput" value={endDate} onChange={this.props.handleEndDateChange} />
                <input type="text" {...inputClass} id="filter" name="filter" value={this.state.filter} disabled={this.props.loading} placeholder="Filter&#8230;" onChange={this.handleFilterChange} />
                <button type="button" className="btn btn-primary mx-2" {...(!this.props.loading && startDate && endDate ? {} : { disabled: true })} onClick={this.handleClearButtonClick}>Reset</button>
                {bulkResubmit}
                {refreshButton}
                {actionableFilter}
                {loadingSpinner}
                {helpText}
            </div>);
    }
}

export class MessageModal extends Component {

    constructor(props) {
        super(props);

        this.resubmit = this.resubmit.bind(this);

        this.state = {
            resubmit_response: ''
        }
    }

    async resubmit() {
        var self = this;
        var authHdr = await getAuthHeader();
        var options = { method: 'POST', headers: authHdr };

        var url = this.props.resubmitUrl + encodeURIComponent(this.props.message);

        fetch(url, options)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    self.setState({ resubmit_response: response.text });
                    self.props.setSuccess(true);
                }
                else {
                    self.props.setSuccess(false);
                }
            })
    }

    render() {

        var metadata = this.props.metadata ? Object.entries(this.props.metadata) : [];


        return (
            <div className="modal fade" id="MessageModal" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.message !== null ? this.props.message : "Loading…"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                this.props.focus_message_text !== null
                                    ? <div>
                                        <div className="row"><div className="col-2">Message text:</div><div className="col-12"><pre>{this.props.message_text}</pre></div></div>
                                    </div>
                                    : (null)
                            }
                            <pre>
                                <table className='table table-striped' aria-labelledby="tableLabel">
                                    <thead>
                                    {metadata.map((x) => {
                                        return (<th>{x[0]}</th>);
                                        }
                                    )}
                                    </thead>
                                    <tbody>
                                        {metadata.map((x) => {
                                            return (<td>{x[1]}</td>);
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </pre>
                        </div>
                        <div className="modal-footer">
                            <i>{this.state.resubmit_response}</i>
                            <ResubmitButton success={this.props.success} variant="secondary" resubmit={this.resubmit} message={this.props.focus_message}>Resubmit</ResubmitButton>{' '}
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.handleCloseModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export class RefreshButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <Button
                    variant={this.props.variant || 'secondary'}
                    onClick={this.props.handleClick}
                    className="btn mx-2">Refresh</Button>
            </Fragment>
            );
    }
}

export class ResubmitButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isResubmitting: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        var self = this;
        this.setState({ isResubmitting: true });

        this.props.resubmit().then(() => {
            self.setState({ isResubmitting: false });
        })

        
    };

    render() {
        return (
            <Fragment>
                <i>{this.props.responseText}</i>
                <Button
                    variant={this.props.variant}
                    disabled={this.state.isResubmitting}
                    onClick={!this.state.isResubmitting ? this.handleClick : null}
                >{this.state.isResubmitting ? 'Resubmitting…' : 'Resubmit'}
                    {this.props.success === true ? '👍' : this.props.success === false ? '👎' : (<SingleDocumentIcon color="white" />)}
                </Button>
            </Fragment>
        );
    }
}

export class BulkResubmitButton extends Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.handleClick = this.handleClick.bind(this);
    }

    async handleClick() {
        var params = new URLSearchParams({ startDate: this.props.startDate + 'T00:00:00.000Z', endDate: this.props.endDate + 'T23:59:59.999Z' });
        var url = this.props.baseUrl + params.toString();
        var authHdr = await getAuthHeader();
        var options = { method: 'POST', headers: authHdr };

        var self = this;
        fetch(url, options)
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    response.text().then((t) => {
                        self.setState({ success: true, responseText: t });
                    })
                    
                }
                else {
                    self.setState({ success: true });
                }
            })

    }

    render() {
        return (
            <Fragment>
                <Button
                    variant={this.props.variant}
                    disabled={this.props.isResubmitting}
                    onClick={!this.props.BulkResubmitting ? this.handleClick : null}
                    className="bulk-resubmit-button"
                >{this.props.BulkResubmitting ? 'Resubmitting…' : 'Bulk Resubmit'}
                    {this.props.success === true ? '👍' : this.props.success === false ? '👎' : (<MultiDocumentIcon color="white" />)}
                </Button>
                {" "}
                <i style={{ maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto' }}>{this.state.responseText}</i>
            </Fragment>
        );
    }
}

export class ReportLoading extends Component {
    render() {
        return (<div className="report-loading">Loading report…</div>);
    }
}

export class CustomerDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isListOpen: false,
        }
    }

    componentDidUpdate() {
        const { isListOpen } = this.state;

        setTimeout(() => {
            if(isListOpen) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.close);
    }

    close = () => {
        this.setState({
            isListOpen: false
        });
    }

    toggleList = () => {
        this.setState((prevState) => ({
            isListOpen: !prevState.isListOpen
        }));
    }

    listWorkspaces = () => {
        const { workspaces, onWorkspaceSelect } = this.props;
        return (
            workspaces.map((workspace) => (
                <button
                    type="button"
                    className="dd-list-item"
                    key={workspace.id}
                    onClick={() => onWorkspaceSelect(workspace)}
                >
                    {workspace.name}
                </button>
            ))
        )
    }

    render() {
        const { isListOpen } = this.state;
        const { currentWorkspace } = this.props;

        return (
            <div className="dd-wrapper">
                <button 
                    type="button" 
                    className="dd-header"
                    key={currentWorkspace.id}
                    onClick={this.toggleList}>
                    <div className="dd-header-title">{currentWorkspace.name}</div>
                    {isListOpen
                        ? <span><CaretUpFill /></span>
                        : <span><CaretDownFill /></span>}
                </button>
                {isListOpen && (
                    <div className="dd-list">
                        <div className="dd-scroll-list">{this.listWorkspaces()}</div>
                    </div>
                )}
            </div>
        );
    }
}

//Filter operations
export function dateDiff(startDate, endDate){
    if (startDate && endDate) {
        return endDate - startDate;
    } else {
        return null;
    }
}

export function newStartDate(date) {
    return newDate(date, false);
}
export function newEndDate(date) {
    return newDate(date, true);
}
export function newDate(date, isEnd) {
    date = !date ? new Date() : new Date(date);

    date = date.toISOString().split('T')[0];
    if (!isEnd) { date += 'T00:00:00.000Z'; }
    else { date += 'T23:59:59.999Z';}

    return new Date(date);
}

//Table operations
var compare = (attr, a, b) => {
    var aVal = a[attr] ? a[attr] : !a.metadata ? null : a.metadata[attr];
    var bVal = b[attr] ? b[attr] : !b.metadata ? null : b.metadata[attr];

    if (aVal === null) {
        return (bVal === null) ? 0 : -1;
    }
    else if (bVal === null) {
        return 1;
    }
    else {
        return aVal === bVal ? 0 : (aVal < bVal ? -1 : 1);
    }
}

var getSortingElem = (target) => {
    var elem = target;
    while (elem && !elem.dataset['attr']) {
        elem = elem.parentNode;
    }
    return elem;
}

var messageSort = (e, msgs, sorted_by) => {
    var elem = getSortingElem(e.target);
    var attr = elem.dataset['attr'];
    if ((sorted_by === null) || (sorted_by !== attr)) {
        msgs.sort((a, b) => { return compare(attr, a, b); });
    }
    else {
        msgs.reverse();
    }

    return msgs;
}

var setPageFlags = (msgs, rows_per_page) => {
    var j = 1;
    for (var ix = 0; ix < msgs.length; ix++) {
        if (!msgs[ix].filtered) {
            j = j + 1;
            msgs[ix].page = Math.ceil((j + 1) / rows_per_page);
        }
        else {
            msgs[ix].page = 0;
        }
    }
    
    return msgs;
}

var filterMessage = (msg, filter) => {
    if (!filter) {
        return false;
    }
    filter = filter.toUpperCase();
    var elements = msg ? Object.entries(msg) : [];

    for (var i = 0; i < elements.length; i++) {
        var obj = elements[i][1];
        var typeOfObj = typeof obj;
        if (typeOfObj === 'string') {
            var upperObj = obj.toUpperCase();
            if (upperObj.includes(filter)) { return false; }
        }
        if (typeOfObj === 'object') {
            if (!filterMessage(obj, filter)) { return false; }
        }        
    }

    return true;

}

var initializeMessage = (msg) => {
    msg.timestampMilliseconds = msg.timestamp ? Date.parse(msg.timestamp) : null;
}

var getMessages = async (baseUrl, start_date, continuationToken) => {
    if (start_date === null) {
        return [];
    }

    var authHdr = await getAuthHeader();
    var options = { method: 'GET', headers: authHdr };
    var paramList = { startDate: start_date.toISOString() };
    if (continuationToken) {
        paramList[continuationToken] = continuationToken;
    }
    var params = new URLSearchParams(paramList);
    var url = baseUrl + params.toString();
    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error(await response.text())
    }
    const data = await response.json();
    if (data) {
        for (var i = 0; i < data.length; i++) {
            data[i].models.forEach(initializeMessage);
            data[i].models.sort((a, b) => { return compare('timestampMilliseconds', a, b); });
            data[i].models.reverse();
        }
    }

    return data[0];
}

export const MessageFunctions = {
    setPageFlags: setPageFlags,
    filterMessage: filterMessage,
    getSortingElem: getSortingElem,
    messageSort: messageSort,
    compare: compare,
    getMessages: getMessages
}

