import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { getJsonWithAuth } from '../../FetchExtensions';
import { RCOError } from '../../models/RCOError';
import { SqlErrorsModal, SqlErrorsTable } from './tables/SqlErrorsTable';
import DateFilter from './tables/DateFilter';
import TableSpinner from './tables/TableSpinner';
import { getCookie } from "../../auth/Utils";

const api = {
	getErrors: async (customerId: string, startDate: Date, endDate: Date, abortController: AbortController) => {
		let userId = getCookie('uid');
		let token = getCookie('token');

		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';
		const params = new URLSearchParams({ startDate: adjustedStartDate, endDate: adjustedEndDate });

		if (userId && token) {
			return getJsonWithAuth<RCOError[]>(`sqlerrors/${userId}/GetAllErrors?${params.toString()}`, abortController);
		}

		return getJsonWithAuth<RCOError[]>(`sqlerrors/GetAllErrors?${params.toString()}`, abortController);
	}
}

interface Props {
	customerId: string;
}

export default function SQLErrors({ customerId }: Props) {
	const [errors, setErrors] = useState<RCOError[]>([]);

	const today = new Date();
	const [fromDate, setFromDate] = useState(today);
	const [toDate, setToDate] = useState(today);
	const dateRangeTooLong = Math.ceil(Math.abs(toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24)) > 7;
	const dateRangeInvalid = fromDate > toDate;

	const [filter, setFilter] = useState('');
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(true);

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);
	const [selectedError, setSelectedError] = useState<RCOError>({
		errorId: '-1',
		errorMessage: '',
		stackTrace: '',
		severity: '',
		actionable: false,
		errorTime: new Date(),
		blobName: '',
		messageTime: new Date()
	});

	const handleGetErrors = useCallback(async (customerId: string, abortController: AbortController) => {
		if (dateRangeInvalid || dateRangeTooLong) {
			return;
		}

		setLoading(true);
		return await api.getErrors(customerId, fromDate, toDate, abortController)
			.then((response) => setErrors(response))
			.catch((err) => console.debug(err))
			.finally(() => setLoading(false));
	}, [fromDate, toDate, dateRangeInvalid, dateRangeTooLong]);


	useEffect(() => {
		var abortController = new AbortController();

		handleGetErrors(customerId, abortController);

		return () => {
			abortController.abort();
		}
	}, [customerId, fromDate, toDate, handleGetErrors]);

	const handleSetFromDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setFromDate(!value ? today : new Date(value));
	}

	const handleSetToDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setToDate(!value ? today : new Date(value));
	}

	const handleSetFilter = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setFilter(value);
	}

	const handleSelectError = (error: RCOError) => {
		setSelectedError(error);
		toggleModal();
	}


	return (
		<Container>
			{dateRangeTooLong && <div><i style={{ maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red' }}>Date range can't be longer than one week.</i></div>}
			{dateRangeInvalid && <div><i style={{ maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red' }}>From date can't be later than To date</i></div>}
			<div className='form-inline my-2'>
				<DateFilter
					fromDate={fromDate}
					selectFromDate={handleSetFromDate}
					toDate={toDate}
					selectToDate={handleSetToDate}
					textFilter={filter}
					textFilterVisible={true}
					applyFilter={handleSetFilter}
					dateRangeInvalid={dateRangeInvalid}
					dateRangeTooLong={dateRangeTooLong}
				/>
				<Button color='secondary' onClick={() => setFilter('')}>Reset</Button>
				<div className="form-check m-2">
					<input type='checkbox' className='form-check-input' id='ShowActionable' checked={checked} onChange={e => setChecked(e.currentTarget.checked)} />
					<label className="form-check-label" htmlFor="ShowActionable">Actionable Only</label>
				</div>
			</div>
			{loading ? <TableSpinner /> :
				<SqlErrorsTable
					columns={[
						{ key: 'customerName', label: 'Customer', columnSizeCss: 'col-2' },
						{ key: 'errorTime', label: 'Timestamp', columnSizeCss: 'col-2' },
						{ key: 'severity', label: 'Severity', columnSizeCss: 'col-1' },
						{ key: 'blobName', label: 'File Name', columnSizeCss: 'col-2' },
						{ key: 'errorMessage', label: 'Error Message', columnSizeCss: 'col-3' }
					]}
					errors={errors}
					handleClick={handleSelectError}
					filter={filter}
					showActionableOnly={checked}
				/>
			}
			<SqlErrorsModal
				show={showModal}
				toggle={toggleModal}
				error={selectedError}
			/>
		</Container>
	)
}
