import React from 'react';
import agent from '../../agent';
import { getJsonWithAuth, getStringWithAuth, postAsyncWithAuth } from '../../FetchExtensions';
import { BlobItem } from '../../models/blobs/BlobItem';
import Blobs, { BlobsAPI } from './Blobs';

//X12 data doesn't always have line breaks, so this makes sure that each segment displays on a new line consistently.
function processLineEndings(text: string): string {
	return text.replaceAll(/~[\r\n]*/g, '~\n');
}

const api: BlobsAPI = {
    getBlobItems: async (customerId: string, startDate: Date, endDate: Date, abortController: AbortController) => {
		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

		const params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
		return getJsonWithAuth<BlobItem[]>(`blobs/${customerId}/claims?${params.toString()}`, abortController);
	},
	getPHIAccess: async() => agent.Configuration.getPHIAccess(),
	getBlobItemContents: async (customerId: string, blobItem: BlobItem) => processLineEndings(await getStringWithAuth(`blobs/${customerId}/claims/${encodeURIComponent(blobItem.id)}`)),
	resubmitBlobItem: async(customerId: string, blobItem: BlobItem) => await postAsyncWithAuth(`blobs/${customerId}/claims/${encodeURIComponent(blobItem.id)}`),
	bulkResubmitBlobItems: async(customerId: string, startDate: Date, endDate: Date) => {
		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

		const params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
		return await postAsyncWithAuth(`blobs/${customerId}/claims?${params.toString()}`);
	}
}

interface Props {
	customerId: string;
}

export default function Claims({customerId}:Props) {
	return(
		<Blobs
			customerId={customerId}
			columns={[
				{ key: 'name', label: 'Name', columnSizeCss: 'col-8' },
				{ key: 'createdOn', label: 'Created On', columnSizeCss: 'col-2'},
				{ metadataKey: 'status', label: 'Status', columnSizeCss: 'col-2'}
			]}
			api={api}
		/>
	)
}