import React, { Component } from 'react';

export class DivotIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '12'}
                height={this.props.size || '12'}
                viewBox="0 0 32 32"
                fill={this.props.color || 'black'}
                className="sort-arrow button-icon"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M16 10.414L2.707 23.707a1 1 0 11-1.414-1.414l14-14a1 1 0 011.414 0l14 14a1 1 0 01-1.414 1.414L16 10.414z"
                    fillRule="evenodd"
                    {...this.props.reversed ? { transform: 'rotate(180 16 16)' } : {}} />
            </svg>);
    }
}

export class CircleCheckIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '1em'}
                height={this.props.size || '1em'}
                viewBox="0 0 16 16"
                className="bi bi-check-circle"
                fill={this.props.color || 'black'}
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path fillRule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
            </svg>);
    }
}

export class CircleXIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '1em'}
                height={this.props.size || '1em'}
                viewBox="0 0 16 16"
                className="bi bi-x-circle-fill"
                fill={this.props.color || 'black'}
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>);
    }
}

export class ClockIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '1em'}
                height={this.props.size || '1em'}
                viewBox="0 0 16 16"
                className="bi bi-clock-history"
                fill={this.props.color || 'black'}
                xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                <path fillRule="evenodd" d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                <path fillRule="evenodd" d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
            </svg>);
    }
}

export class SingleDocumentIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '1em'}
                height={this.props.size || '1em'}
                fill={this.props.color || 'black'}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="sgl-docs-icon button-icon">
                <path fillRule="evenodd" transform="rotate(180 16 16)"
                    d="M4 31a1 1 0 01-1-1V2a1 1 0 011-1h24a1 1 0 011 1v21c0 .043-.003.085-.008.126L29 23a1 1 0 01-.031.25l-.009.03a.916.916 0 01-.031.091l-.012.027a.914.914 0 01-.137.227l-.073.082-7 7a.895.895 0 01-.093.082l.093-.082a1 1 0 01-.309.21l-.027.012a.914.914 0 01-.091.031l-.03.008A.921.921 0 0121 31H4zM27 3H5v26h15v-6a1 1 0 011-1h6V3zm-1.415 21H22v3.585L25.585 24z" />
            </svg>);
    }
}

export class MultiDocumentIcon extends Component {
    render() {
        return (
            <svg width={this.props.size || '1em'}
                height={this.props.size || '1em'}
                fill={this.props.color || 'black'}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="multi-docs-icon button-icon">
                <path fillRule="evenodd"
                    d="M23 1c.043 0 .085.003.126.008L23 1a1 1 0 01.25.031l.03.009c.03.009.061.02.091.031l.027.012a.914.914 0 01.227.137l.082.073 6 6c.03.03.057.06.082.093l-.082-.093a1 1 0 01.21.309l.012.027c.012.03.022.06.031.091l.008.03a.921.921 0 01.025.133L30 8v16a1 1 0 01-1 1h-7v5a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h9V2a1 1 0 011-1h10zM12.983 6.999L4 7v22h16V14h-6a1 1 0 01-1-1V7l-.017-.001zM22 3h-8v2h.25a1 1 0 01.696.282l6.75 6.546a1 1 0 01.304.717V23h6V9h-5a1 1 0 01-1-1V3zm-2.998 9L15 8.12V12h4.002zM24 4.415V7h2.586L24 4.415z" />
            </svg>);
    }
}

export class CaretDownFill extends Component {
    render() {
        return (
            <svg width={this.props.size || '16'}
                height={this.props.size || '16'}
                fill={this.props.color || 'currentColor'}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="bi bi-caret-down-fill">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
        );
    }
}

export class CaretUpFill extends Component {
    render() {
        return (
            <svg width={this.props.size || '16'}
                height={this.props.size || '16'}
                fill={this.props.color || 'currentColor'}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="bi bi-caret-up-fill">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
        );
    }
}