import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { doLogin, getUser } from '../auth/AuthProvider.js';
import { logTrace } from '../logging/LogProvider.js';
import { Layout } from './Layout';
import { Home } from '../components/Home';
import Claims from '../components/admin/Claims';
import HL7 from '../components/admin/HL7';
import Mar from '../components/admin/Mar';
import Admin from '../components/admin/Customers'
import { Reports } from '../components/Reports.js';
import { ErrorPage } from '../components/ErrorPage.js';
import { isInternal } from '../auth/Utils.js';
import CustomerNotFound from '../components/CustomerNotFound';

import '../styles/site.css';
import '../styles/fonts.css';
import '../styles/fonts/global-fonts.css';
import agent from '../agent';
import SQLErrors from '../components/admin/SQLErrors';
import AuditData from '../components/admin/AuditData';

export class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.setSelectedCustomer = this.setSelectedCustomer.bind(this);

        this.state = {
            token_cache: [],
            customers: [],
            selectedCustomer: null,
            reports: null,
            date_range: [null, null],
            cx_user: null
        };
    }

    async componentDidMount() {
        var abortController = new AbortController();
        await logTrace('Doing login procedure');
        var user;
        await doLogin().then(async () => { user = await getUser(); });

        var reportsList = await this.getReports();

        var customers = await agent.Customers.getCustomers(abortController);
        this.setState({
            reports: reportsList,
            customers: customers,
            selectedCustomer: customers[0],
            cx_user: user });

        return () => abortController.abort();
    }

    async getReports() {
        if (isInternal()) {
            return [];
        }

        var url = 'configuration/ReportNames';
        const response = await fetch(url);
        if (!response.ok) {
            return [];
        }
        var responseJson = await response.json();
        return responseJson.entries;
    }

    setSelectedCustomer = (customer) => {
        this.setState({ ...this.state, selectedCustomer: customer });
    }

    deleteCustomer = (customer) => {
        let customers = this.state.customers;
        const index = customers.map(c => c.id).indexOf(customer.id);
        customers.splice(index, 1);
        this.setState({ customers: customers, selectedCustomer: customers[0] });

    }

    renderRouteFromReport(report) {
        var self = this;
        return (<Route key={'report-' + report.pageName} exact path={report.url} component={() => (<Reports reportName={report.pageName} selectedCustomer={self.state.selectedCustomer} tokenCache={self.state.token_cache} />)} />);
    }

    render() {
        const isInternalSession = isInternal();
        if (!this.state.reports) {
            return (null);
        }

        //Empty arrays are actually truthy, so we also need to check the currently selected customer as well as the customers array length.
        if (!this.state.customers || !this.state.selectedCustomer || this.state.customers.length < 1) {
            return <CustomerNotFound />
        }

        var self = this;
        return (
            <Layout reportsList={self.state.reports} customers={self.state.customers} selectedCustomer={self.state.selectedCustomer} {...(self.state.cx_user ? { user: self.state.cx_user } : {})} setSelectedCustomer={self.setSelectedCustomer}>
                <Switch>
                    <Route exact path='/' component={() => (<Home selectedCustomer={self.state.selectedCustomer} />)} />
                    {isInternalSession && <Route exact path='/hl7-messages' render={() => (<HL7 customerId={self.state.selectedCustomer.id} />)} />}
                    {isInternalSession && <Route path='/claims' render={() => <Claims customerId={self.state.selectedCustomer.id} />} />}
                    {isInternalSession && <Route path='/errors' render={() => <SQLErrors customerId={self.state.selectedCustomer.id} />} />}
                    {isInternalSession && <Route exact path='/mar-messages' render={() => (<Mar customer={self.state.selectedCustomer} />)} />}
                    {isInternalSession && <Route exact path='/audit-data' render={() => (<AuditData customer={self.state.selectedCustomer} />)} />}
                    {isInternalSession && <Route exact path='/admin' component={() => (<Admin customerId={self.state.selectedCustomer.id} setSelectedCustomer={this.setSelectedCustomer} handleDeletedCustomer={this.deleteCustomer} />)} />}
                    <Route key="report-base" exact path='/reports' component={() => (<Reports reportName={'Overview'} selectedCustomer={self.state.selectedCustomer} tokenCache={self.state.token_cache} />)} />
                    {self.state.reports.map(self.renderRouteFromReport.bind(self))}
                    <Route exact path='/signin' component={Home} />
                    <Route exact path='/notfound' component={ErrorPage} />
                    <Route component={ErrorPage} />
                </Switch>
            </Layout>
        );
    }
}

/*
import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { doLogin, getUser } from '../auth/AuthProvider.js';
import { logTrace } from '../logging/LogProvider.js';
import { Layout } from './Layout';
import { Home } from '../components/Home';
import Claims from '../components/admin/Claims';
import HL7 from '../components/admin/HL7';
import Mar from '../components/admin/Mar';
import Admin from '../components/admin/Customers'
import { Reports } from '../components/Reports.js';
import { ErrorPage } from '../components/ErrorPage.js';
import { isInternal } from '../auth/Utils.js';
import CustomerNotFound from '../components/CustomerNotFound';

import '../styles/site.css';
import '../styles/fonts.css';
import agent from '../agent';
import SQLErrors from '../components/admin/SQLErrors';

export const App = () => {
    const [selectedCustomer, setSelectedCustomer] = useState({ id: '' });
    const [tokenCache, setTokenCache] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [reports, setReports] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [cxUser, setCxUser] = useState({});
    const [isInternalSession] = useState(isInternal());


    useEffect(async () => {
        var abortController = new AbortController();
        await logTrace('Doing login procedure');
        var user;
        await doLogin().then(async () => { user = await getUser(); });

        var reportsList = await getReports();

        var customerList = await agent.Customers.getCustomers(abortController);

        setReports(reportsList);
        setCustomers(customerList);
        setSelectedCustomer(customerList[0]);
        setCxUser(user);

        return () => abortController.abort();
    }, []);

    useEffect(() => {
        setSelectedCustomer()
    }, [selectedCustomer]);

    const getReports = async () => {
        if (isInternal()) {
            return [];
        }

        var url = 'configuration/ReportNames';
        const response = await fetch(url);
        if (!response.ok) {
            return [];
        }
        var responseJson = await response.json();
        return responseJson.entries;
    }

    //setSelectedCustomer = (customer) => {
    //    this.setState({selectedCustomer: customer});
    //}

    const renderRouteFromReport = (report) => {
        return (<Route key={'report-' + report.pageName} exact path={report.url} component={() => (<Reports reportName={report.pageName} selectedCustomer={selectedCustomer} tokenCache={tokenCache} />)} />);
    }


    return (
        <>
            <Layout reportsList={reports} customers={customers} selectedCustomer={selectedCustomer} user={cxUser} setSelectedCustomer={setSelectedCustomer}>
                <Switch>
                    <Route exact path='/' component={() => (<Home selectedCustomer={selectedCustomer} />)} />
                    {isInternalSession && <Route exact path='/hl7-messages' render={() => (<HL7 customerId={selectedCustomer.id} />)} />}
                    {isInternalSession && <Route path='/claims' render={() => <Claims customerId={selectedCustomer.id} />} />}
                    {isInternalSession && <Route path='/errors' render={() => <SQLErrors customerId={selectedCustomer.id} />} />}
                    {isInternalSession && <Route exact path='/mar-messages' render={() => (<Mar customer={selectedCustomer} />)} />}
                    {isInternalSession && <Route exact path='/customers/:id/admin' component={() => (<Admin customerId={selectedCustomer.id} />)} />}
                    <Route key="report-base" exact path='/reports' component={() => (<Reports reportName={'Overview'} selectedCustomer={selectedCustomer} tokenCache={tokenCache} />)} />
                    {reports && reports.map(report => renderRouteFromReport(report))}
                    <Route exact path='/signin' component={Home} />
                    <Route exact path='/notfound' component={ErrorPage} />
                    <Route component={ErrorPage} />
                </Switch>
            </Layout>
        </>
    )
}
*/
