import React, { Component } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { handleLoginRedirect, getAuthHeader } from '../auth/AuthProvider.js';
import { isInternal } from '../auth/Utils.js';
import { ReportLoading } from './NavComponents.js';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        const {selectedCustomer} = this.props;
        this.state = {
            loading: true,
            access_url: null,
            access_token: null,
            token_expire: null,
            selectedCustomer: selectedCustomer,
            report_id: null
        };
    }

    async componentDidMount() {
        await handleLoginRedirect();

        const params = await this.getAccessParams();
        if (params) {
            this.setState({
                loading: false,
                access_url: params.embedURL,
                access_token: params.embedToken,
                token_expire: params.tokenExpiration,
                report_id: params.reportId
            });
        }
        else {
            this.setState({ loading: false });
        }
    }

    async getAccessParams() {
        if (!isInternal()) {
            return null;
        }

        const authHdr = await getAuthHeader();
        const options = { method: 'GET', headers: authHdr };

        if(!this.state.selectedCustomer) {
            return null;
        }
        const url = 'powerbi/home?' + new URLSearchParams({workspaceId: this.state.selectedCustomer.powerBIWorkspaceId}).toString();
 
        const response = await fetch(url, options);
        if (response.ok) {
            return await response.json();
        }
        console.log(response);

        return null;
    }

    render() {
        if (this.state.loading) {
            return (<ReportLoading />);
        }
        else if (!this.state.access_url) {
            return (null);
        }

        return (
            <div className="embed-container">
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: this.state.report_id,
                        embedUrl: this.state.access_url,
                        accessToken: this.state.access_token,
                        tokenType: models.TokenType.Embed,
                        settings: { navContentPaneEnabled: true }
                    }}
                />
            </div>
        );
    }
}
