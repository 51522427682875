import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu reportsList={this.props.reportsList} customers={this.props.customers} selectedCustomer={this.props.selectedCustomer} {...(this.props.user ? { user: this.props.user } : {})} setSelectedCustomer={this.props.setSelectedCustomer} />
                {this.props.children}
            </div>
        );
    }
}
