export const getCookie = function(name) {
    var cookies = document.cookie.split(';'); // May need to make this account for CNAME
    var val = null;
    for (var i in cookies) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            val = cookie.slice(name.length + 1);
            break;
        }
    }
    return val;
};

export const isInternal = function () {
    return getCookie('uid') === null && getCookie('token') === null;
};