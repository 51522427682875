import { getAuthHeader } from "./auth/AuthProvider";

const addAbortToConfig = (config?: RequestInit, abortController?: AbortController): RequestInit | undefined => {
	if(!abortController) {
		return config;
	}

	return {
		...config,
		signal: abortController.signal
	}
}

const processResponseAsJson = <T>(response: Response): Promise<T> => {
	if (response.ok){
		return response.json() as Promise<T>;
	}

	return response.text()
		.then((error) => {throw error;})
}

const processResponseAsText = (response: Response): Promise<string> => {
	if(response.ok) {
		return response.text();
	}

	return response.text()
		.then((error) => {throw error;})
}

const fetchNoAuth = async (url: string, abortController?: AbortController, config?: RequestInit) => {
	config = addAbortToConfig(config, abortController);

	return fetch(url, config);
}

const fetchWithAuth = async (url: string, abortController?: AbortController, config?: RequestInit) => {
	config = addAbortToConfig(config, abortController);

	return fetch(url, {
		...config,
		headers: await getAuthHeader() as HeadersInit
	});
}

export const getAsync = async (url: string, abortController?: AbortController, config?: RequestInit) => await fetchNoAuth(url, abortController, {
	...config,
	method: 'GET'
});

export const getAsyncWithAuth = async (url: string, abortController?: AbortController, config?: RequestInit) => await fetchWithAuth(url, abortController, {
	...config,
	method: 'GET'
});

export const getJson = async<T> (url: string, abortController?: AbortController, config?: RequestInit): Promise<T> => 
	await getAsync(url, abortController, config)
		.then((response) => processResponseAsJson<T>(response));

export const getJsonWithAuth = async<T>(url: string, abortController?: AbortController, config?: RequestInit): Promise<T> =>
	await getAsyncWithAuth(url, abortController, config)
		.then((response) => processResponseAsJson<T>(response));

export const getString = async (url: string, abortController?: AbortController, config?: RequestInit): Promise<string> => 
	await getAsync(url, abortController, config)
		.then((response) => processResponseAsText(response));

export const getStringWithAuth = async (url: string, abortController?: AbortController, config?: RequestInit): Promise<string> => 
	await getAsyncWithAuth(url, abortController, config)
		.then((response) => processResponseAsText(response));

export const postAsync = async (url: string, abortController?: AbortController, config?: RequestInit) => await fetchNoAuth(url, abortController, {
	...config,
	method: 'POST'
});

export const postAsyncWithAuth = async(url: string, abortController?: AbortController, config?: RequestInit) => await fetchWithAuth(url, abortController, {
	...config,
	method: 'POST'
});