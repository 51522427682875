import React, { Component } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Redirect } from 'react-router-dom';
import { getAuthHeader } from '../auth/AuthProvider.js';
import { isInternal } from '../auth/Utils.js';
import { getABCUrl } from '../auth/authConfig.js';
import { ReportLoading } from './NavComponents.js';
import { logTrace, logException } from '../logging/LogProvider.js';

export class Reports extends Component {

    static idleTimer;

    constructor(props) {
        super(props);
        const { reportName } = this.props;

        this.state = {
            report_name: reportName,
            access_url: null,
            access_token: null,
            token_expire: null,
            report_id: null,
            redirect: false,
            update_skipped: false,
            timeout_id: null
        };

        //Idle timer
        this.resetTimer = this.resetTimer.bind(this);
        this.resetTimer();
        document.onmousemove = this.resetTimer;
        document.onkeypress = this.resetTimer;
    }

    async resetTimer() {
        clearTimeout(Reports.idleTimer);
        Reports.idleTimer = setTimeout(() => {
            window.location.href = this.state.abc_url + 'hss/hssb2b/en/logout';
        }, 1800000);  //30 minutes
    }

    async componentDidMount() {
        this.visibilityChangeCallback = this.handleVisibilityChange.bind(this);
        document.addEventListener('visibilitychange', this.visibilityChangeCallback);

        this.refreshAccessToken();

        var url = await getABCUrl();
        await this.setState({ abc_url: url });

        //update favicon
        if (!isInternal()) {
            document.querySelector("link[rel='shortcut icon']").href = "/abcfavicon.svg";
        }
    }

    async componentWillUnmount() {
        clearTimeout(Reports.idleTimer);

        document.removeEventListener('visibilitychange', this.visibilityChangeCallback);
        if (this.state.timeout_id !== null) {
            clearTimeout(this.state.timeout_id);
        }
    }

    async handleVisibilityChange() {
        if (this.state.update_skipped && document.visibilityState === 'visible') {
            this.refreshAccessToken();
        }
    }

    async refreshAccessToken() {
        if (document.visibilityState === 'hidden') {
            this.setState({ update_skipped: true });
            return;
        }

        try {
            var params = null;

            // Need to find a new way of doing this with multiple customers now
            // if (this.props.tokenCache && this.props.tokenCache.length) {
            //     var now = Date.now();
            //     var expire = Date.parse(this.props.tokenCache[0].tokenExpiration);
            //     if (expire - now >= 600000) { // 10 minutes
            //         params = this.props.tokenCache[0];
            //     }
            // }

            if (params === null) {
                params = await this.getAccessParams();
                if (!params) {
                    this.setState({ redirect: true });
                    return;
                }
                else if (this.props.tokenCache) {
                    if (this.props.tokenCache.length) {
                        this.props.tokenCache[0] = params;
                    }
                    else {
                        this.props.tokenCache.push(params);
                    }
                }
            }

            var pageId = await this.getPageId(params.reportId, this.props.selectedCustomer.powerBIWorkspaceId, this.state.report_name);
            var refreshTimeoutId = setTimeout(() => this.refreshAccessToken(), 300000); // Refresh the PowerBI token every 5 minutes.
            this.setState({
                access_url: params.embedURL + '&pageName=' + pageId,
                access_token: params.embedToken,
                token_expire: params.tokenExpiration,
                report_id: params.reportId,
                update_skipped: false,
                timeout_id: refreshTimeoutId
            });
        }
        catch {
            this.setState({ redirect: true });
        }
    }

    async getAccessParams() {
        try {
            var isInternalSession = isInternal();
            var authHdr = isInternalSession ? await getAuthHeader() : {};
            var options = { method: 'GET', headers: authHdr };
            var url = 'powerbi';
            if (isInternalSession) {
                url += '/Internal';
            } else {
                url += '/get';
            }
            url += '?' + new URLSearchParams({ workspaceId: this.props.selectedCustomer.powerBIWorkspaceId }).toString();
            await logTrace(`Making Power BI access token request to ${url}`);
            return fetch(url, options)
                .then((response) => {
                    if (response.status === 401) {
                        return response.json()
                            .then((body) => {
                                if (body?.redirect) {
                                    window.location.href = body.redirect;
                                }
                                return Promise.resolve();
                            });
                    }
                    else if (!response.ok) {
                        return logTrace(`Power BI access token request failed with status ${response.status}`).then(() => Promise.reject());
                    }

                    return response.json();
                })
                .catch((err) => {
                    console.error(err);
                    return logException(err).then(() => Promise.reject());
                });
        }
        catch {
            this.setState({ redirect: true });
            return Promise.reject();
        }
    }

    async getPageId(reportId, workspaceId, pageDisplayName) {
        try {
            var isInternalSession = isInternal();
            var authHdr = isInternalSession ? await getAuthHeader() : {};
            var options = { method: 'GET', headers: authHdr };
            var url = 'powerbi';
            if (isInternalSession) {
                url += '/PageIdInternal';
            }
            else {
                url += '/PageId';
            }
            url += '?';
            var params = new URLSearchParams({ reportId: reportId, workspaceId: workspaceId, pageDisplayName: pageDisplayName });
            url += params.toString();
            var response = await fetch(url, options);
            if (response.ok) {
                return await response.text();
            }
            else {
                throw new Error(await response.text())
            }
        }
        catch {
            this.setState({ redirect: true });
        }
    }

    render() {
        var isInternalSession = isInternal();

        if (this.state.redirect) {
            return (<Redirect to='/notfound' />);
        }
        if (this.state.access_url === null) {
            return (<ReportLoading />);
        }

        var self = this;
        return (
            <div className="embed-container">
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: self.state.report_id,
                        embedUrl: self.state.access_url,
                        accessToken: self.state.access_token,
                        tokenType: models.TokenType.Embed,
                        settings: { navContentPaneEnabled: isInternalSession }
                    }} /></div>);
    }
}