import React, { useState } from 'react';
import { Redirect }  from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { Customer } from '../../../models/Customer';
import { getAuthHeader } from "../../../auth/AuthProvider";

import './Table.css';

export interface CustomerColumn {
    key: keyof Customer;
    label: string;
    columnSizeCss?: string;
    type: string;
    notEditable?: boolean;
}

interface CustomerTableProps {
    columns: CustomerColumn[];
    customer: Customer;
    setSelectedCustomer: Function;
    handleDeletedCustomer: Function;
}

export function CustomerTable({
    columns,
    customer,
    setSelectedCustomer,
    handleDeletedCustomer
}: CustomerTableProps) {
    const [editing, setEditing] = useState(false);
    const [updatedCustomer, setUpdatedCustomer] = useState({});
    const [errMsg, seterrMsg] = useState('');

    if (!customer) {
        return (<div className="d-flex justify-content-center tbl-no-results">No customer info</div>)
    }

    const createNewCustomer = async () => {
        const postOptions = {
            method: 'POST',
            headers: { ...await getAuthHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify({ })
        }

        const createResult = await fetch(`/api/customers/create`, postOptions);
        const result = await createResult.json();
        setSelectedCustomer(result);
        //window.location.reload();
    }

    const saveCustomer = async () => {

        var combinedObj = {...customer, ...updatedCustomer }

        const postOptions = {
            method: 'POST',
            headers: { ...await getAuthHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(combinedObj)
        }

        try {
            const result = await fetch(`/api/customers/${customer.id}/save`, postOptions);
            setSelectedCustomer(await result.json());
        }
        catch (err) {
            seterrMsg('Error Saving Customer Record');
        }

    }

    const deleteCustomer = async () => {
        const postOptions = {
            method: 'POST',
            headers: { ...await getAuthHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(customer)
        }

        try {
            fetch(`/api/customers/${customer.id}/delete`, postOptions).then(result => {
                handleDeletedCustomer(customer);
            })
        }

        catch (err) {
            seterrMsg('Error Deleting Customer Record');
        }
    }

    const handleFieldChange = (obj : any) => {
        setUpdatedCustomer({ ...updatedCustomer, ...obj })
    }

    const errDiv = errMsg ? <div className='row alert alert-danger'><span style={{ margin: 'auto' }}>{errMsg}</span></div> : null

    return (
        <>
            {errDiv}
            <div className='row'>

            <Button type='button' color="primary" style={{ marginLeft: 'auto', marginRight: '0', marginBottom: '5px' }} onClick={createNewCustomer}>Create New Customer</Button>
            </div>
            {
                columns.map((column) => <CustomerRow key={column.key} customer={customer} column={column} editing={editing} handler={handleFieldChange}></CustomerRow>)
            }
            <div className='row'>
                {editing && <Button type='button' color="primary" style={{marginRight: '0', marginTop: '5px' }} onClick={saveCustomer}>Save</Button>}
                <Button type='button' color="primary" style={{ marginRight: '0', marginTop: '5px' }} onClick={() => setEditing(!editing)}>{editing ? 'Cancel' : 'Edit'}</Button>
                <Button type='button' color="primary" style={{ marginLeft: 'auto', marginRight: '0', marginTop: '5px' }} onClick={deleteCustomer}>Delete</Button>
            </div>
        </>
    )

}

interface RowProps {
    customer: Customer;
    column: CustomerColumn;
    editing: boolean;
    handler: Function;
}

export function CustomerRow({ customer, column, editing, handler }: RowProps) {

    return (
        <Row>
            <Col className="bg-light border font-weight-bold" style={{ textTransform: 'capitalize' }} title={column.key}>{column.label}</Col>
            <EditableColumn type={column.type} editing={editing} field={column.key} value={customer[column.key] ?? ''} handler={handler} editable={!column.notEditable} />
        </Row>
    )
}


export function EditableColumn(props: any) {

    const [fieldValue, setFieldValue] = useState(props.value);
    
    const handleFieldChange = (value : any) => {
        if (props.type === 'boolean') {
            value = value === 'true';
        }

        if (props.type === 'array') {
            value = value.toString().split(',');
            value = value.map(function (x: string) { return x.trim(); });
        }

        let obj: { [key: string]: any } = {};
        obj[props.field] = value;
        props.handler(obj);
        setFieldValue(value);
    }

    const displayValue = props.type === 'boolean' ? (fieldValue === true ? 'Yes' : 'No') : props.type === 'array' ? JSON.stringify(fieldValue) : fieldValue;

    const editor = props.type === 'boolean' ? (<select value={fieldValue} className='form-control' onChange={(e) => handleFieldChange(e.target.value)}><option value='true'>Yes</option><option value='false'>No</option></select>) : (<input type='text' className='form-control' style={{ width: '85%' }} value={fieldValue} onChange={(e) => handleFieldChange(e.target.value)} />);

    const field = props.editing && props.editable ? editor : (<div>{displayValue}</div>);

    return (<Col className="bg-light border"><div className='input-group'>{field}</div></Col>)
}
