import { BlobContainer } from "./models/blobs/BlobContainer";
import { BlobItem } from "./models/blobs/BlobItem";
import { Customer } from "./models/Customer";
import { getCookie } from "./auth/Utils";
import { getAsync, getJson, getJsonWithAuth, getStringWithAuth, postAsyncWithAuth } from "./FetchExtensions";

const Blobs = {
    getBlobContainers: async(baseUrl: string) => await getJsonWithAuth<BlobContainer[]>(baseUrl),
    getBlobItems: async(baseUrl: string, startDate: Date, endDate: Date) => {
        // Only care about Dates, not times
        let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        let adjustedEndDate = endDate.toISOString().split('T')[0]+'T00:00:00.000Z';

        let params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
        return await getJsonWithAuth<BlobItem[]>(`${baseUrl}?${params.toString()}`);
    },
    getBlobItemContents: async(baseUrl: string, blobContainer: BlobContainer, blobItem: BlobItem) => getStringWithAuth(`${baseUrl}/${blobContainer.name}/${encodeURIComponent(blobItem.id)}`),
    resubmitBlobITem: async(baseUrl: string, blobContainer: BlobContainer, blobItem: BlobItem) => await postAsyncWithAuth(`${baseUrl}/${blobContainer.name}/${encodeURIComponent(blobItem.id)}/resubmit`),
    bulkResubmitBlobItems: async(baseUrl: string, blobContainer: BlobContainer, startDate: Date, endDate: Date) => {
        let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
        let adjustedEndDate = endDate.toISOString().split('T')[0]+'T00:00:00.000Z';
        let params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
        return await postAsyncWithAuth(`${baseUrl}/${blobContainer.name}/resubmitBulk?${params.toString()}`);
    }
}

const Configuration = {
    getReportNames: async() => await getAsync('configuration/ReportNames')
        .catch(() => []),
    getPHIAccess: async () => await getJsonWithAuth<boolean>('configuration/PHIAccess')
        .catch(() => false)
}

const Customers = {
    getCustomers: async(abortController: AbortController): Promise<Customer[]> =>{
        let userId = getCookie('uid');
        let token = getCookie('token');
        if(userId && token){
            return await getJson<Customer[]>(`api/customers/${userId}?token=${token}`, abortController, {
                cache: 'no-store'
            }).catch((ex) => {
                console.error(ex);
                return [];
            });
        }
        return getJsonWithAuth<Customer[]>('api/customers', abortController)
            .catch((ex) => {
                console.error(ex);
                return [];
            })
    },
    getErrors: async(customerId: string, abortController: AbortController) => {
        return getJsonWithAuth<any[]>(`api/customers/${customerId}/errors`, abortController)
            .catch((ex) => console.error(ex));
    }
}

const agent = {
    Blobs,
    Configuration,
    Customers
}

export default agent;