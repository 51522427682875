var _iKey = null;

export const getInstKey = function () {
    if (_iKey !== null) {
        return Promise.resolve(_iKey);
    }
    return fetch('configuration/InstrumentationKey')
        .then((response) => {
            return response.text();
        })
        .then((txt) => {
            _iKey = txt;
            return Promise.resolve(_iKey);
        });
}