import React from 'react'
import { Customer } from '../../models/Customer';

import './Dropdown.css'

interface Props {
	selectedCustomer: Customer,
	dropdownOpen: boolean
}

function CaretDownFill(){
	return(
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="bi bi-caret-down-fill dropdown-header-caret">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
		</svg>
	)
}

function CaretUpFill(){
	return(
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="bi bi-caret-up-fill dropdown-header-caret">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
		</svg>
	)
}

export default function DropdownToggle({selectedCustomer: customer, dropdownOpen}: Props) {
	return (
		<>
			<div className='dropdown-header-wrapper'>
				<div className='dropdown-header'>
					<div className='dropdown-header-text'>
						{customer.name}
					</div>
				</div>
			</div>
			{dropdownOpen ? <CaretUpFill /> : <CaretDownFill />}
		</>
	)
}