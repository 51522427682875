import React, { useState } from 'react';
import { Customer } from '../../models/Customer';
import DropdownMenu from './DropdownMenu';
import DropdownToggle from './DropdownToggle';

import './Dropdown.css';

interface Props {
	customers: Customer[];
	selectedCustomer: Customer;
	setSelectedCustomer: (customer: Customer) => void;
}

export default function Dropdown({ customers, selectedCustomer, setSelectedCustomer}: Props) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState);

	return(
		<div className='dropdown' onClick={toggle}>
			<DropdownToggle selectedCustomer={selectedCustomer} dropdownOpen={dropdownOpen} />
			{dropdownOpen && <DropdownMenu customers={customers} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} />}
		</div>
	)
}