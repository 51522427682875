import React, { Component } from 'react';
import { isInternal } from '../auth/Utils.js';

export class ErrorPage extends Component {

    render() {
        let error = this.props && this.props.location && this.props.location.error ? this.props.location.error : {};

        return (
            <div className="page-not-found">
                <h1>Page Not Found</h1>
                <div>
                    Sorry! We were unable to find the page you were looking for.
                </div>
                {isInternal() &&
                    <div style={{ textAlign: 'left' }}>
                        <pre>{error.message}</pre>
                    </div>
                }
            </div>
        )
    }
}

export default ErrorPage;