import React, { ChangeEvent } from 'react';
import { Row, Col, Label } from 'reactstrap';
interface Props {
    fromDate: Date;
    selectFromDate: (event: ChangeEvent<HTMLInputElement>) => void;
    toDate: Date;
    selectToDate: (event: ChangeEvent<HTMLInputElement>) => void;
    textFilter: string;
    textFilterVisible: boolean;
    applyFilter: (event: ChangeEvent<HTMLInputElement>) => void;
    dateRangeInvalid: boolean;
    dateRangeTooLong: boolean;
}

export default function DateFilter({
    fromDate,
    selectFromDate,
    toDate,
    selectToDate,
    textFilter,
    textFilterVisible,
    applyFilter,
    dateRangeInvalid,
    dateRangeTooLong
}: Props) {
    const inputClass = { className: dateRangeInvalid || dateRangeTooLong ? "form-control mr-sm-2 mx-2 is-invalid" : "form-control mr-sm-2 mx-2" };

    return (
        <>
            <Row className={`pb-3`}>
                <Col xs="6">
                    <Label for="fromDateInput" >From</Label>
                    <input type="date" name="fromDateInput" id="fromDateInput" value={fromDate.toISOString().slice(0, 10)} onChange={selectFromDate} {...inputClass} />
                </Col>
                <Col xs="6">
                    <Label for="ToDateInput" >To</Label>
                    <input type="date" name="toDateInput" id="toDateInput" value={toDate.toISOString().slice(0, 10)} onChange={selectToDate} {...inputClass} />
                </Col>
            </Row>
            <Row className={`pb-3`} style={{ visibility: textFilterVisible ? 'visible' : 'hidden' }}>
                <Col xs="12">
                    <Label for="filter" >Filter</Label>
                    <input type="text" name="filter" id="filter" value={textFilter} placeholder="Filter&#8230;" onChange={applyFilter} className="form-control mr-sm-2 mx-2" />
                </Col>
            </Row>
        </>
    )
}