import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig, getTokenScopes } from './authConfig.js';
import { isInternal } from './Utils.js';

var _msalAuth = null;

const getAuth = async function () {
    if (_msalAuth !== null) {
        return _msalAuth;
    }
    var config = await getMsalConfig();
    _msalAuth = new PublicClientApplication(config);
    return _msalAuth;
};

export const handleLoginRedirect = async function () {
    var auth = await getAuth();
    await auth.handleRedirectPromise();
}

export const doLogin = async function () {
    var auth = await getAuth();
    return auth.handleRedirectPromise()
        .then(async () => {
            var accts = auth.getAllAccounts();
            if (isInternal() && !(accts && accts.length)) {
                var tokenScopes = await getTokenScopes();
                await auth.loginPopup(tokenScopes)
                    .catch(async (err) => {
                        console.log(err);
                        await auth.loginRedirect(tokenScopes);
                    });
            }
        });
};

export const doLogout = async function () {
    if (!isInternal()) {
        return false;
    }
    var user = await getUser();
    if (!user) {
        return false;
    }
    var auth = await getAuth();
    await auth.handleRedirectPromise()
        .then(async () => {
            await auth.logout({ account: user });
        });
    return true;
};

export const getAuthHeader = async function () {
    if (!isInternal()) {
        return {};
    }
    var token = null;
    var request = await getTokenScopes();
    var user = await getUser();
    if (user) {
        request.account = user;
    }
    var auth = await getAuth();
    await auth.acquireTokenSilent(request)
        .then((response) => { token = response.accessToken; })
        .catch(async function (err) {
            console.log(err);
            if (err.errorCode.indexOf('interaction_required') !== -1) {
                await auth.acquireTokenPopup(request)
                    .then((rspPopup) => { token = rspPopup.accessToken; })
                    .catch(async function (errPopup) {
                        console.log(errPopup);
                        await auth.acquireTokenRedirect(request)
                            .then((rspRedirect) => { token = rspRedirect.accessToken; })
                            .catch((errRedirect) => { console.log(errRedirect); });
                    });
            }
        });
    if (token === null) {
        return null;
    }
    var hdr = { 'Authorization': 'Bearer ' + token };
    return hdr;
};

export const getUser = async function () {
    var auth = await getAuth();
    var accts = auth.getAllAccounts();
    if (accts && accts.length) {
        return accts[0];
    }
    return null;
}