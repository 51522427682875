import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Container } from 'reactstrap';
import { BlobItem } from '../../models/blobs/BlobItem';
import { BlobsColumn, BlobsModal, BlobsTable } from './tables/BlobsTable';
import DateFilter from './tables/DateFilter';
import TableSpinner from './tables/TableSpinner';

export interface BlobsAPI {
	getPHIAccess: () => Promise<boolean>;
	getBlobItems: (customerId: string, startDate: Date, endDate: Date, abortController: AbortController) => Promise<BlobItem[]>;
	getBlobItemContents: (customerId: string, blobItem: BlobItem) => Promise<string>;
	resubmitBlobItem: (customerId: string, blobItem: BlobItem) => Promise<Response>;
	bulkResubmitBlobItems: (customerId: string, startDate: Date, endDate: Date) => Promise<Response>;
}

interface Props {
	customerId: string;
	columns: BlobsColumn[];
	api: BlobsAPI;
}

export default function Blobs({
	customerId,
	columns,
	api
}: Props) {
	const [hasPHIAccess, setPHIAccess] = useState(false);

	const [blobItems, setBlobItems] = useState<BlobItem[]>([]);

	const today = new Date();
	const [fromDate, setFromDate] = useState(today);
	const [toDate, setToDate] = useState(today);
	const dateRangeTooLong = Math.ceil(Math.abs(toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24)) > 7;
	const dateRangeInvalid = fromDate > toDate;

	const [filter, setFilter] = useState('');

	const [loading, setLoading] = useState(true);
	const [isResubmitting, setIsResubmitting] = useState(false);
	const [didSubmit, setDidSubmit] = useState(false);
	const [resubmitSuccess, setResubmitSuccess] = useState(false);
	const [resubmitResponse, setResubmitResponse] = useState('');

	const [alertVisible, setAlertVisible] = useState(true);
	const onAlertDismiss = () => {
		setAlertVisible(false);
		setDidSubmit(false);
		setAlertVisible(true);
	}

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);
	const [selectedBlobItem, setSelectedBlobItem] = useState<BlobItem>();
	const [selectedBlobItemMessage, setSelectedBlobItemMessage] = useState('');


	const handleGetBlobItems = useCallback(async(customerId: string, abortController: AbortController) => {
		if(dateRangeInvalid || dateRangeTooLong){
			return;
		}
		setLoading(true);
		api.getBlobItems(customerId, fromDate, toDate, abortController)
			.then((response) => setBlobItems(response))
			.catch((err) => console.debug(err))
			.finally(() => setLoading(false))
	},[fromDate, toDate, dateRangeInvalid, dateRangeTooLong, api]);

	useEffect(() => {
		var abortController = new AbortController();

		api.getPHIAccess()
			.then((response) => {
				setPHIAccess(response)
				if(response){
					handleGetBlobItems(customerId, abortController);
				}
			});

		return () => {
			abortController.abort();
		}
	}, [customerId, fromDate, toDate, handleGetBlobItems, api])


	const handleSetFromDate = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setFromDate(!value ? today : new Date(value));
	}

	const handleSetToDate = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setToDate(!value ? today : new Date(value));
	}

	const handleSetFilter = (event: ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setFilter(value);
	}

	const handleSelectBlob = async (blobItem: BlobItem) => {
		setSelectedBlobItem(blobItem);
		await api.getBlobItemContents(customerId, blobItem)
			.then((response) => setSelectedBlobItemMessage(response));
		toggleModal();
	}

	const handleResubmit = async(promise: Promise<Response>) => {
		setIsResubmitting(true);
		await promise
			.then((response) => {
				if(response.ok){
					setResubmitSuccess(true);
					response.text().then((text) => setResubmitResponse(text));
				}
				else{
					setResubmitSuccess(false);
				}
			})
			.catch((err) => {
				setResubmitSuccess(false);
				console.debug(err);
			})
			.finally(() => {
				setDidSubmit(true);
				setIsResubmitting(false);
			});
	}

	const handleBulkResubmit = async() => await handleResubmit(api.bulkResubmitBlobItems(customerId, fromDate, toDate));
	const handleResubmitBlobItem = async(blobItem: BlobItem) => await handleResubmit(api.resubmitBlobItem(customerId, blobItem));

	const handleResubmitClick = async(blobItem?: BlobItem) => {
		if (blobItem) {
			await handleResubmitBlobItem(blobItem)
				.finally(() => toggleModal());
		}
	};
	


	if(!hasPHIAccess){
		return (
			<div className="page-not-found">
				<h1>Missing PHI Permissions</h1>
				<div>You are missing the required permissions to view PHI Data.</div>
			</div>
		)
	}

	return(
		<Container>
            {didSubmit && (resubmitSuccess
                ? <Alert color='success' isOpen={alertVisible} toggle={onAlertDismiss}><span role="img" aria-label="thumbs up">👍</span> Resubmit Queued Successfully! Message Id: {resubmitResponse}</Alert>
                : <Alert color='warning' isOpen={alertVisible} toggle={onAlertDismiss}><span role="img" aria-label="thumbs down">👎</span> Resubmit Failed... Check logs for details.</Alert>)
            }
			{dateRangeTooLong && <div><i style={{maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red'}}>Date range can't be longer than one week.</i></div>}
 			{dateRangeInvalid && <div><i style={{maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red'}}>From date can't be later than To date</i></div>}		
			
			<div className='form-inline my-2'>
				<DateFilter
					fromDate={fromDate}
					selectFromDate={handleSetFromDate}
					toDate={toDate}
					selectToDate={handleSetToDate}
					textFilter={filter}
					textFilterVisible={true}
					applyFilter={handleSetFilter}
					dateRangeInvalid={dateRangeInvalid}
					dateRangeTooLong={dateRangeTooLong}
				/>
				<Button color='secondary' onClick={() => setFilter('')}>Reset</Button>
				<Button color='primary' className='mx-2' disabled={isResubmitting} onClick={handleBulkResubmit}>
					{isResubmitting ? 'Resubmitting...' : 'Bulk Resubmit'}
				</Button>
			</div>
			{loading ? <TableSpinner /> :
				<BlobsTable
					columns={columns}
					blobItems={blobItems}
					handleClick={handleSelectBlob}
					filter={filter}
				/>
			}
			<BlobsModal
				show={showModal}
				toggle={toggleModal}
				blobItem={selectedBlobItem}
				blobItemMessage={selectedBlobItemMessage}
				isResubmitting={isResubmitting}
				handleResubmitClick={handleResubmitClick}
			/>
		</Container>

	)
}