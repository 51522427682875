import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { getJsonWithAuth } from '../../FetchExtensions';
import { AuditDataRowsModal, AuditDataTable } from './tables/PowerBIAccessTable';
import DateFilter from './tables/DateFilter';
import TableSpinner from './tables/TableSpinner';
import { PowerBIAccessAudit } from '../../models/PowerBIAccessAudit';

const api = {
	getAuditData: async (startDate: Date, endDate: Date, abortController: AbortController) => {
		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

		const params = new URLSearchParams({ startDate: adjustedStartDate, endDate: adjustedEndDate });
		return getJsonWithAuth<PowerBIAccessAudit[]>(`powerbi/getaudits?${params.toString()}`, abortController);
	}
}

export default function AuditData() {
	const [data, setData] = useState<PowerBIAccessAudit[]>([]);

	const today = new Date();
	const [fromDate, setFromDate] = useState(today);
	const [toDate, setToDate] = useState(today);
	const dateRangeTooLong = Math.ceil(Math.abs(toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24)) > 7;
	const dateRangeInvalid = fromDate > toDate;

	const [filter, setFilter] = useState('');
	const [loading, setLoading] = useState(true);

	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);
	const [selectedAuditData, setSelectedAuditData] = useState<PowerBIAccessAudit>({
		accessAuditKey: 0,
		sessionTokenHashed: '',
		reportId: '',
		workspaceId: '',
		pageDisplayName: '',
		timestamp: new Date(),
		userName: ''
	});

	const handleGetAuditData = useCallback(async (abortController: AbortController) => {
		if (dateRangeInvalid || dateRangeTooLong) {
			return;
		}

		setLoading(true);
		return await api.getAuditData(fromDate, toDate, abortController)
			.then((response) => setData(response))
			.catch((err) => console.debug(err))
			.finally(() => setLoading(false));
	}, [fromDate, toDate, dateRangeInvalid, dateRangeTooLong]);


	useEffect(() => {
		var abortController = new AbortController();

		handleGetAuditData(abortController);

		return () => {
			abortController.abort();
		}
	}, [fromDate, toDate, handleGetAuditData]);

	const handleSetFromDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setFromDate(!value ? today : new Date(value));
	}

	const handleSetToDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setToDate(!value ? today : new Date(value));
	}

	const handleSetFilter = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setFilter(value);
	}

	const handleSelectAuditData = (data: PowerBIAccessAudit) => {
		setSelectedAuditData(data);
		toggleModal();
	}


	return (
		<Container>
			{dateRangeTooLong && <div><i style={{ maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red' }}>Date range can't be longer than one week.</i></div>}
			{dateRangeInvalid && <div><i style={{ maxWidth: '25em', maxHeight: '20ex', overflowY: 'auto', color: 'red' }}>From date can't be later than To date</i></div>}
			<div className='form-inline my-2'>
				<DateFilter
					fromDate={fromDate}
					selectFromDate={handleSetFromDate}
					toDate={toDate}
					selectToDate={handleSetToDate}
					textFilter={filter}
					textFilterVisible={false}
					applyFilter={handleSetFilter}
					dateRangeInvalid={dateRangeInvalid}
					dateRangeTooLong={dateRangeTooLong}
				/>
			</div>
			{loading ? <TableSpinner /> :
				<AuditDataTable
					columns={[
						{ key: 'timestamp', label: 'Timestamp', columnSizeCss: 'col-3' },
						{ key: 'pageDisplayName', label: 'Page Display Name', columnSizeCss: 'col-3' },
						{ key: 'userName', label: 'User Name', columnSizeCss: 'col-2' },
						{ key: 'workspaceId', label: 'Workspace ID', columnSizeCss: 'col-5' },
						{ key: 'reportId', label: 'Report ID', columnSizeCss: 'col-5' }
					]}
					auditRows={data}
					handleClick={handleSelectAuditData}
					filter={filter}
				/>
			}
			<AuditDataRowsModal
				show={showModal}
				toggle={toggleModal}
				auditData={selectedAuditData}
			/>
		</Container>
	)
}