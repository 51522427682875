import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { BlobItem, HL7Metadata, Metadata } from '../../../models/blobs/BlobItem';
import { DivotIcon } from '../../../styles/icons';
import { PageBar, MessageFunctions } from '../../NavComponents';

import './Table.css';

export interface BlobsColumn {
	key?: keyof BlobItem,
	metadataKey?: Metadata | HL7Metadata,
	label: string,
	columnSizeCss: string
}

interface TableProps {
	columns: BlobsColumn[];
	blobItems: BlobItem[];
	handleClick: (blobItem: BlobItem) => void;
	filter: string;
}

export function BlobsTable({
	columns,
	blobItems,
	handleClick,
	filter
}: TableProps) {
	const rows_per_page = 50;
	const [sortedBy, setSortedBy] = useState('id');
	const [sortReverse, setSortReverse] = useState(true);
	const [sortedBlobItems, setSortedBlobItems] = useState(blobItems.sort((a, b) => compare(a, b, sortedBy)));
	const [page, setPage] = useState(1);

	const handleSort = (columnName: string) => {
		setSortReverse(sortedBy === columnName && !sortReverse);

		if(sortedBy !== columnName) {
			setSortedBlobItems(sortedBlobItems.sort((a, b) => compare(a, b, columnName)));
		}
		else {
			setSortedBlobItems(sortedBlobItems.reverse());
		}
		setSortedBy(columnName);
	}

	function compare(a: BlobItem, b: BlobItem, columnName: string) {
		let aVal: string;
		let bVal: string;
		if(columnName in a){
			aVal = (a as any)[columnName] ?? null;
			bVal = (b as any)[columnName] ?? null;
		}
		else {
			aVal = (a.metadata as any)[columnName] ?? null;
			bVal = (b.metadata as any)[columnName] ?? null;
		}

		if(!aVal) {
			return bVal ? 0 : -1;
		}
		else if(!bVal){
			return 1;
		}

		return aVal === bVal ? 0 : (aVal < bVal ? -1 : 1);
	}

	if(!(blobItems && blobItems.length)){
		return(<div className="d-flex justify-content-center tbl-no-results">No Items</div>)
	}

	const filteredBlobItems = sortedBlobItems.filter((blobItem) => blobItem.id.toLowerCase().includes(filter.toLowerCase()))

	MessageFunctions.setPageFlags(filteredBlobItems, rows_per_page);

	return(
		<div>
			Page: {page}
			<Table striped className='table'>
				<BlobsHead columns={columns} sortedBy={sortedBy} sortReversed={sortReverse} handleSort={handleSort}/>
				<tbody>
					{
						filteredBlobItems.filter((b) => b.page === page)
							.map((blobItem) => <BlobsRow key={blobItem.id} columns={columns} blobItem={blobItem} handleClick={handleClick} />)
					}
				</tbody>
			</Table>
			<PageBar
				count={filteredBlobItems.length}
				handlePageClick={setPage}
				rows_per_page={rows_per_page}
				current_page={page}
			/>
		</div>
	)
}

interface HeadProps {
	columns: BlobsColumn[];
	sortedBy: string;
	sortReversed: boolean;
	handleSort: (columnName: string) => void;
}

export function BlobsHead({columns, sortedBy, sortReversed, handleSort}: HeadProps) {
	return(
		<thead>
			<tr className='d-flex'>
				{
					columns.map((column) =>
						<th key={String(column.key ?? column.metadataKey)} className={column.columnSizeCss}>
							<Button color="link" onClick={() => handleSort(String(column.key ?? column.metadataKey))}>
								{column.label}
								{(sortedBy === column.key || sortedBy === column.metadataKey) && <DivotIcon reversed={sortReversed} />}
							</Button>
						</th>
					)
				}
			</tr>
		</thead>
	)
}

interface RowProps {
	blobItem: BlobItem;
	columns: BlobsColumn[];
	handleClick: (blobItem: BlobItem) => void;
}

export function BlobsRow({blobItem, columns, handleClick}: RowProps) {
	return (
		<tr key={blobItem.id} className="d-flex">
			{
				columns.map((column) => {
					if(column.key) {
						if(column.key === 'name'){
							return(
								<td key={column.key} className={column.columnSizeCss}>
									<Button color='link' className="truncate-text" onClick={() => handleClick(blobItem)}>{blobItem.name}</Button>
								</td>
							)
						}
						if(column.key === 'createdOn') {
							return(
								<td key={column.key} className={column.columnSizeCss}>{new Date(blobItem.createdOn).toDateString()}</td>
							)
						}
						return(
							<td key={column.key} className={column.columnSizeCss}>{blobItem[column.key]}</td>
						)
					}
					if(column.metadataKey){
						return(
							<td key={column.metadataKey} className={column.columnSizeCss}>{(blobItem.metadata as any)[column.metadataKey]}</td>
						)
					}
					return(<></>)
				})
			}
		</tr>
	)
}

interface ModalProps {
	show: boolean;
	toggle:() => void;
	blobItem?: BlobItem;
	blobItemMessage: string;
	isResubmitting: boolean;
	handleResubmitClick: (blobItem: BlobItem | undefined) => Promise<void>;
}

export function BlobsModal({
	show,
	toggle,
	blobItem,
	blobItemMessage,
	isResubmitting,
	handleResubmitClick
}: ModalProps) {
	return (
		<Modal isOpen={show} toggle={toggle} scrollable={true} size='xl'>
			<ModalHeader toggle={toggle}>{blobItem?.id}</ModalHeader>
			<ModalBody>
				<pre>
					<h4>Metadata</h4>
					<Table striped>
						<thead>
							<tr>
								{Object.keys(blobItem?.metadata ? blobItem.metadata : []).map(key => <th key={key}>{key}</th>)}
							</tr>
						</thead>
						<tbody>
							<tr>
								{Object.entries(blobItem?.metadata ? blobItem.metadata : []).map(([key, value]) => <td key={key}>{value}</td>)}
							</tr>
						</tbody>
					</Table>
					<h4>Message Text</h4>
					{blobItemMessage}
				</pre>
			</ModalBody>
			<ModalFooter>
				<Button color='secondary' disabled={isResubmitting} onClick={() => handleResubmitClick(blobItem)}>
					{isResubmitting ? 'Resubmitting...' : 'Resubmit'}
				</Button>
				<Button color='primary' onClick={toggle}>Close</Button>
			</ModalFooter>
		</Modal>
	)
}