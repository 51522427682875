var _msalConfig = null;
var _tokenScopes = null;
var _abcBaseUrl = null;
var _abcLinks = null;

export const getMsalConfig = async function () {
    if (_msalConfig !== null) {
        return _msalConfig;
    }
    var response = await fetch('configuration/AuthConfig');
    _msalConfig = await response.json();
    return _msalConfig;
};

export const getTokenScopes = async function () {
    if (_tokenScopes !== null) {
        return _tokenScopes;
    }
    var response = await fetch('configuration/TokenScopes');
    _tokenScopes = await response.json();
    return _tokenScopes;
};

export const getABCUrl = async function () {
    if (_abcBaseUrl !== null) {
        return _abcBaseUrl;
    }
    var response = await fetch('configuration/ABCBaseURL');
    _abcBaseUrl = await response.text();
    return _abcBaseUrl;
};

export const getABCLinks = async function () {
    if (_abcLinks !== null) { return _abcLinks; }
    var response = await fetch('configuration/ABCLinks');
    _abcLinks = await response.json();
    return _abcLinks;
}
