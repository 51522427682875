import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { RCOError } from '../../../models/RCOError';
import { DivotIcon } from '../../../styles/icons';
import { PageBar } from '../../NavComponents';

import './Table.css';

export interface SqlErrorsColumn {
    key: keyof RCOError;
    label: string;
    columnSizeCss: string;
}

interface TableProps {
    columns: SqlErrorsColumn[];
    errors: RCOError[];
    handleClick: (error: RCOError) => void;
    filter: string;
    showActionableOnly: boolean;
}

export function SqlErrorsTable({
    columns,
    errors,
    handleClick,
    filter,
    showActionableOnly
}: TableProps) {
    const [sortedBy, setSortedBy] = useState('errorId');
    const [sortReverse, setSortReverse] = useState(true);
    const [sortedErrors, setSortedErrors] = useState(errors.sort((a, b) => compare(a, b, sortedBy)))
    const [page, setPage] = useState(1);
    const rowsPerPage = 50;

    useEffect(() => {
        const filteredErrors = errors.filter(error => error.errorMessage.toLowerCase().includes(filter.toLowerCase()) ||
            error.blobName?.toLowerCase().includes(filter.toLowerCase())).map((error, i) => { return { ...error, page: (i / rowsPerPage | 0) + 1 } });
        setSortedErrors(filteredErrors.sort((a, b) => compare(a, b, sortedBy)));
    }, [errors, filter, sortedBy])

    const handleSort = (columnName: string) => {
        setSortReverse(sortedBy === columnName && !sortReverse);

        if (sortedBy !== columnName) {
            setSortedErrors(errors.sort((a, b) => compare(a, b, columnName)).map((error, i) => { return { ...error, page: (i / rowsPerPage | 0) + 1 } }));
        }
        else {
            setSortedErrors(sortedErrors.reverse().map((error, i) => { return { ...error, page: (i / rowsPerPage | 0) + 1 } }));
        }

        setSortedBy(columnName);
    }

    function compare(a: RCOError, b: RCOError, columnName: string) {
        let aVal: string;
        let bVal: string;

        aVal = (a as any)[columnName] ?? null;
        bVal = (b as any)[columnName] ?? null;

        if (!aVal) {
            return bVal ? 0 : -1;
        }
        else if (!bVal) {
            return 1;
        }

        if (columnName === 'Timestamp') {
            let aTime = new Date(aVal);
            let bTime = new Date(bVal);
            return aTime === bTime ? 0 : (aTime < bTime ? -1 : 1);
        }

        if (isNaN(+aVal)) {
            aVal = aVal.toLowerCase();
            bVal = bVal.toLowerCase();
        }

        return aVal === bVal ? 0 : (aVal < bVal ? -1 : 1);
    }

    if (!(errors && errors.length)) {
        return (<div className="d-flex justify-content-center tbl-no-results">No Errors</div>)
    }

    return (
        <div>
            <Table striped className='table'>
                <SqlErrorsHead columns={columns} sortedBy={sortedBy} sortReversed={sortReverse} handleSort={handleSort} />
                <tbody>
                    {
                        (showActionableOnly ? sortedErrors.filter((error) => error.actionable) : sortedErrors)
                            .filter((error) => error.page === page)
                            .map((error) => <SqlErrorsRow key={`${error.errorId}-${error.customerName}`} columns={columns} error={error} handleClick={handleClick} />)
                    }
                </tbody>
            </Table>
            <PageBar
                count={errors.length}
                handlePageClick={setPage}
                rows_per_page={rowsPerPage}
                current_page={page}
            />
        </div>
    )

}

interface HeadProps {
    columns: SqlErrorsColumn[];
    sortedBy: string;
    sortReversed: boolean;
    handleSort: (columnName: string) => void;
}

export function SqlErrorsHead({ columns, sortedBy, sortReversed, handleSort }: HeadProps) {
    return (
        <thead>
            <tr>
                {
                    columns.map((column) =>
                        <th key={column.key} className={column.columnSizeCss}>
                            <Button color="link" onClick={() => handleSort(column.key)}>
                                {column.label}
                                {sortedBy === column.key && <DivotIcon reversed={sortReversed} />}
                            </Button>
                        </th>
                    )
                }
            </tr>
        </thead>
    )
}

interface RowProps {
    error: RCOError;
    columns: SqlErrorsColumn[];
    handleClick: (error: RCOError) => void;
}

export function SqlErrorsRow({ error, columns, handleClick }: RowProps) {
    return (
        <tr key={error.errorId}>
            {
                columns.map((column) => {
                    if (column.key === 'customerName') {
                        return (
                            <td key={column.key} className={column.columnSizeCss}>{error.customerName}</td>
                        )
                    }

                    if (column.key === 'errorTime') {
                        return (
                            <td key={column.key} className={column.columnSizeCss}>{new Date(error.errorTime).toLocaleString()}</td>
                        )
                    }

                    if (column.key === 'errorMessage') {
                        return (
                            <td key={column.key} className={`truncate-text ${column.columnSizeCss}`}>
                                <Button color='link' className={`column-btn`} onClick={() => handleClick(error)}>
                                    {error.errorMessage}
                                </Button>
                            </td>
                        )
                    }

                    if (column.key === 'blobName') {
                        return (
                            <td key={column.key} className={'truncate-text ' + column.columnSizeCss}>{error.blobName?.split('/').pop()}</td>
                        )
                    }
                    return (<td key={column.key} className={column.columnSizeCss}>{error[column.key]}</td>)
                })
            }
        </tr>
    )
}

interface ModalProps {
    show: boolean;
    toggle: () => void;
    error: RCOError;
}

export function SqlErrorsModal({
    show,
    toggle,
    error
}: ModalProps) {
    return (
        <Modal isOpen={show} toggle={toggle} scrollable={true} size='xl'>
            <ModalHeader toggle={toggle}>Error - {error.errorId}</ModalHeader>
            <ModalBody>
                <h4>Error Message</h4>
                {error.errorMessage}
                <h4>Error Timestamp</h4>
                {error.errorTime}
                <h4>Stack Trace</h4>
                <pre>{error.stackTrace}</pre>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}