import React, { useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PowerBIAccessAudit } from '../../../models/PowerBIAccessAudit';
import { PageBar } from '../../NavComponents';

import './Table.css';

export interface AuditColumn {
	key: keyof PowerBIAccessAudit;
	label: string;
	columnSizeCss: string;
}

interface TableProps {
	columns: AuditColumn[];
	auditRows: PowerBIAccessAudit[];
	handleClick: (audit: PowerBIAccessAudit) => void;
	filter: string;
}

export function AuditDataTable({
	columns,
	auditRows
}: TableProps) {
	const [sortedBy, setSortedBy] = useState('auditId');
	const [sortReverse, setSortReverse] = useState(true);
	const [sortedAuditRows, setSortedAuditRows] = useState(auditRows.sort((a, b) => compare(a, b, 'auditId')))
	const [page, setPage] = useState(1);
	const rowsPerPage = 50;

	const handleSort = (columnName: string) => {
		setSortReverse(sortedBy === columnName && !sortReverse);

		if (sortedBy !== columnName) {
			setSortedAuditRows(sortedAuditRows.sort((a, b) => compare(a, b, columnName)));
		}
		else {
			setSortedAuditRows(sortedAuditRows.reverse());
		}
		setSortedBy(columnName);
	}

	function compare(a: PowerBIAccessAudit, b: PowerBIAccessAudit, columnName: string) {
		let aVal: string;
		let bVal: string;
		aVal = (a as any)[columnName] ?? null;
		bVal = (b as any)[columnName] ?? null;

		if (!aVal) {
			return bVal ? 0 : -1;
		}
		else if (!bVal) {
			return 1;
		}

		return aVal === bVal ? 0 : (aVal < bVal ? -1 : 1);
	}

	if (!(auditRows && auditRows.length)) {
		return (<div className="d-flex justify-content-center tbl-no-results">No Audit Rows</div>)
	}

	return (
		<div>
			<Table striped>
				<AuditRowsHead columns={columns} sortedBy={sortedBy} sortReversed={sortReverse} handleSort={handleSort} />
				<tbody>
					{
						(sortedAuditRows)
							.map((data) => <AuditDataRow key={data.accessAuditKey} columns={columns} accessAudit={data} />)
					}
				</tbody>
			</Table>
			<PageBar
				count={auditRows.length}
				handlePageClick={setPage}
				rows_per_page={rowsPerPage}
				current_page={page}
			/>
		</div>
	)

}

interface HeadProps {
	columns: AuditColumn[];
	sortedBy: string;
	sortReversed: boolean;
	handleSort: (columnName: string) => void;
}

export function AuditRowsHead({ columns, sortedBy, sortReversed, handleSort }: HeadProps) {
	return (
		<thead>
			<tr>
				{
					columns.map((column) =>
						<th key={column.key} className={column.columnSizeCss}>
							<Button color="link" onClick={() => handleSort(column.key)}>
								{column.label}
								{sortedBy === column.key && <div />}
							</Button>
						</th>
					)
				}
			</tr>
		</thead>
	)
}

interface RowProps {
	accessAudit: PowerBIAccessAudit;
	columns: AuditColumn[];
}

export function AuditDataRow({ accessAudit, columns }: RowProps) {
	return (
		<tr key={accessAudit.accessAuditKey}>
			{
				columns.map((column) => {
					if (column.key === 'timestamp') {
						return (
							<td key={column.key} className={column.columnSizeCss}>{new Date(accessAudit.timestamp).toLocaleString()}</td>
						)
					}
					if (column.key === 'pageDisplayName') {
						return (
							<td key={column.key} className={column.columnSizeCss}>{accessAudit.pageDisplayName}</td>
						)
					}
					if (column.key === 'userName') {
						return (
							<td key={column.key} className={column.columnSizeCss}>{accessAudit.userName}</td>
						)
					}
					if (column.key === 'workspaceId') {
						return (
							<td key={column.key} className={column.columnSizeCss}>{accessAudit.workspaceId}</td>
						)
					}
					if (column.key === 'reportId') {
						return (
							<td key={column.key} className={column.columnSizeCss}>{accessAudit.reportId}</td>
						)
					}
					return (<td key={column.key} className={column.columnSizeCss}>{accessAudit[column.key]}</td>)
				})
			}
		</tr>
	)
}

interface ModalProps {
	show: boolean;
	toggle: () => void;
	auditData: PowerBIAccessAudit;
}

export function AuditDataRowsModal({
	show,
	toggle,
	auditData
}: ModalProps) {
	return (
		<Modal isOpen={show} toggle={toggle} scrollable={true} size='xl'>
			<ModalHeader toggle={toggle}>Audit Item - {auditData.accessAuditKey}</ModalHeader>
			<ModalBody>
				<h4>Page Display Name</h4>
				{auditData.pageDisplayName}
				<h4>Audit Timestamp</h4>
				{auditData.timestamp}
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={toggle}>Close</Button>
			</ModalFooter>
		</Modal>
	)
}