import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { getInstKey } from './loggingConfig.js';

var _appInsights = null;

const getAppInsights = function () {
    if (_appInsights !== null) {
        return Promise.resolve(_appInsights);
    }
    return getInstKey()
        .then((iKey) => {
            var options = { config: { instrumentationKey: iKey } };
            _appInsights = new ApplicationInsights(options);
            _appInsights.loadAppInsights();
            _appInsights.trackPageView();
            _appInsights.addTelemetryInitializer(() => true);
            return Promise.resolve(_appInsights);
        });
};

export const logTrace = function (msg) {
    return getAppInsights()
        .then((ai) => {
            ai.trackTrace({ message: msg, severityLevel: SeverityLevel.Information });
        })
        .catch(console.error);
};

export const logException = function (err) {
    return getAppInsights()
        .then((ai) => {
            ai.trackException({ exception: err });
        })
        .catch(console.error);
};