import React from 'react';
import { Customer } from '../../models/Customer';

import './Dropdown.css';

interface Props {
	customers: Customer[];
	selectedCustomer: Customer;
	setSelectedCustomer: (customer: Customer) => void;
}

export default function DropdownMenu({ customers, selectedCustomer, setSelectedCustomer }: Props) {
	return (
		<div className='dropdown-menu-wrapper'>
			<div className='dropdown-menu-window'>
				<div className='dropdown-facility-selection'>
					<div className='facility-list'>
						<div className='facility-header'>FACILITIES</div>
						{customers.map(customer =>{
							return(
								<div 
									key={customer.id} 
									className={`facility-row-wrapper ${customer.id === selectedCustomer.id ? 'facility-row-wrapper-active' : ''}`}
									onClick={() => {
										if (customer.id !== selectedCustomer.id){
											setSelectedCustomer(customer);
										}
									}
								}>
									<div className='facility-row'>
										<div className='facility-row-customer-name'>{customer.name}</div>
										<span className='facility-row-customer-corp-key'>
											{customer.invoiceUnits && customer.invoiceUnits[0] ?
												customer.invoiceUnits[0] + (customer.invoiceUnits.length == 1 ? "" : ` + ${customer.invoiceUnits.length-1} more`)
												: ""}
										</span>
										{customer.id === selectedCustomer.id &&
											<div className='facility-row-selected-badge'>
												<div className='facility-row-selected-badge-font'>
													<div className='facility-row-selected-badge-text'>Selected</div>
												</div>
											</div>
										}
									</div>
								</div>
							)}
						)}
					</div>
				</div>
			</div>
		</div>
	)
}