import React from 'react';
import agent from '../../agent';
import { getJsonWithAuth, getStringWithAuth, postAsyncWithAuth } from '../../FetchExtensions';
import { BlobItem } from '../../models/blobs/BlobItem';
import Blobs, { BlobsAPI } from './Blobs';

//The HL7 spec only requires carriage returns as line separators, so normalizing all combinations to newlines makes messages display consistently in the browser.
function processLineEndings(text: string): string {
	return text.replaceAll(/[\r\n]+/g, '\n');
}

const api: BlobsAPI = {
    getBlobItems: async (customerId: string, startDate: Date, endDate: Date, abortController: AbortController) => {
		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

		const params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
		return getJsonWithAuth<BlobItem[]>(`blobs/${customerId}/hl7?${params.toString()}`, abortController);
	},
	getPHIAccess: async() => agent.Configuration.getPHIAccess(),
	getBlobItemContents: async (customerId: string, blobItem: BlobItem) => processLineEndings(await getStringWithAuth(`blobs/${customerId}/hl7/${encodeURIComponent(blobItem.id)}`)),
	resubmitBlobItem: async(customerId: string, blobItem: BlobItem) => await postAsyncWithAuth(`blobs/${customerId}/hl7/${encodeURIComponent(blobItem.id)}`),
	bulkResubmitBlobItems: async(customerId: string, startDate: Date, endDate: Date) => {
		let adjustedStartDate = startDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
		let adjustedEndDate = endDate.toISOString().split('T')[0] + 'T23:59:59.999Z';

		const params = new URLSearchParams({startDate: adjustedStartDate, endDate: adjustedEndDate});
		return await postAsyncWithAuth(`blobs/${customerId}/hl7?${params.toString()}`);
	}
}

interface Props {
	customerId: string;
}

export default function HL7({customerId}:Props) {
	return(
		<Blobs
			customerId={customerId}
			columns={[
				{ key: 'name', label: 'Name', columnSizeCss: 'col-2'},
				{ key: 'createdOn', label: 'CreatedOn', columnSizeCss: 'col-2'},
				{ metadataKey: 'msh-9', label: 'Message Type', columnSizeCss: 'col-2'},
				{ metadataKey: 'msh-10', label: 'Message Id', columnSizeCss: 'col-2'},
				{ metadataKey: 'ackcode', label: 'ACK Code', columnSizeCss: 'col-2'},
				{ metadataKey: 'status', label: 'Status', columnSizeCss: 'col-2'}
			]}
			api={api}
		/>
	)
}